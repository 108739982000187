export default {
    nameTest: 'KTP姓名输入格式有误',
    idCardTest: 'KTP号码输入格式有误',
    rfcTest: '请输入正确的RFC',
    childTest: '请输入正确的孩子数量',
    liveAddressTest: '请输入正确的地址',
    emailTest: '邮箱输入格式有误',
    whatsappTest: 'Whatsapp号码输入格式有误',
    companyNameTest: '请输入正确的公司名称',
    schoolNameTest: '请输入正确的学校名称',
    professionalNameTest: '请输入正确的专业名称',
    companyAddressTest: '请输入正确的公司地址',
    iphoneTest: '青输入09开头11位或9开头10位数字',
    iphoneTestNullFirst: '公司电话',
    asLoclIphoneError: '紧急联系人号码不能与注册号码相同，请更改',
    asIphoneError: '选择手机号不能一致',
    bankNumberTset: '请输入银行账号',
    selectRight: '请选择正确手机号',
    phoneLengthError: '请输入10-12位手机号'
};
