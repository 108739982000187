<template>
  <div class="empty" />
</template>

<script>
export default {
  name: 'ImgEmpty',
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
.empty{
  width: 550px;
  height: 406px;
  background: url(~@/assets/images/public/empty.png) no-repeat center center;
  background-size: 100%;
}
</style>
