/* eslint-disable no-undef */
import router from '../router'
export default {
  cancleView() { // 关闭页面
    console.log('关闭页面||安卓返回--')
   giwiandafaadig.eaH5PageClose()
  },

  submitLocation() { // 定位抓取
    console.log('定位抓取--')
   giwiandafaadig.eaH5CollectData4GPS()
  },

  // 基本信息权限获取
  checkLocationPermission(){
    console.log('获取权限开始')
    giwiandafaadig.locationPermission()
    console.log('获取权限结束')
  },
  
  // 跳转到活体认证页
  goIdentityVerPage(){
    console.log('跳转到活体认证页开始')
    giwiandafaadig.goIdentityVerPage()
    console.log('跳转到活体认证页结束')
  },

 
  // 调取相机相册
  opencarema() {
   giwiandafaadig.pm_device_use_camera()
  },
  // 抓取分辨率
  fenbianlvzhuaqu() {
   giwiandafaadig.pm_grab_resolution_ratio()
  },
  // ios返回
  goBack(params) {
   giwiandafaadig.eaH5PageClose()
    // giwiandafaadig.eaH5PageBack.postMessage(params)
  },
  // 退出登录
  loginOut() {
    console.log('退出登录--')
    localStorage.removeItem('dataFromSubmit')
    localStorage.removeItem('buryingPointParams')
   giwiandafaadig.eaH5UserLogout()
  },
  // 退出登录503
  loginOutFiveZeroThree() {
    console.log('退出登录503')
    localStorage.removeItem('dataFromSubmit')
    localStorage.removeItem('buryingPointParams')
   giwiandafaadig.XqsbXRVzRW()
  },
  // 跳转修改密码页
  changePassWord() {
   giwiandafaadig.pm_account_change_pwd()
  },
  // 跳转认证身份证信息
  goIdCardInfo(params) {
   giwiandafaadig.pm_page_open_identity_info.postMessage(params)
  },
  // 立即还款
  repayment(params) {
   giwiandafaadig.eaH5Route4PayNow.postMessage(params)
  },
  // 本期还款记录
  paymentHistory(params) {
   giwiandafaadig.pm_home_paymentHistory.postMessage(params)
  },
  // 本期还款详情
  billingDetails(params) {
   giwiandafaadig.eaH5Route4RepaymentDetails.postMessage(params)
  },

  // 待还款页面高度 目前
  webContentHeight(params) {
   giwiandafaadig.pm_update_web_content_height.postMessage(params)
  },

  // 注册成功
  registerSuccess(params) {
    console.log('登录成功参数---', JSON.stringify(params))
   giwiandafaadig.eaH5UserLoginSuccess(JSON.stringify(params))
  },

  // 登录成功
  loginSuccess(params) {
    console.log('登录成功参数---', JSON.stringify(params))
    giwiandafaadig.eaH5UserLoginSuccess(JSON.stringify(params))
  },

  // 修改密码
  userChangePassword(params) {
    console.log('登录成功参数---', JSON.stringify(params))
    giwiandafaadig.eaH5UserLoginSuccess(JSON.stringify(params))
  },

  

  

  threePartyPayment(params) {
    console.log('跳转第三方支付链接')
   giwiandafaadig.IUFCaFyeAQ(JSON.stringify(params))
  },

  // 打开通讯录
  unfoldContact() {
    console.log('打开通讯录--')
   giwiandafaadig.openPhoneBook()
  },

 

  // 认证完成提交
  supporder() {
    console.log('认证完成提交--')
   giwiandafaadig.finishAndSubmitOrder()
  },

  // 去首页
  homeclick() {
    console.log('去首页--')
   giwiandafaadig.homeclick()
  },

  // 切换语言英语
  changeLGen() {
    console.log('切换语言英语--')
   giwiandafaadig.changeLaEnglish()
  },

  // 切换语言菲律宾
  changeLGph() {
    console.log('切换语言菲律宾--')
   giwiandafaadig.changeLaTagalog()
  },

  // 隐藏展示底部的tab true false
  isShowBottomTabinter(params) {
    console.log('隐藏展示底部的tab--' + params)
    try {
      giwiandafaadig.isShowBottomTabinter(params)
    } catch (error) {
      
    }
  
  },

  // 改变状态栏颜色传字符串 例如#ffffff
  changeStatusBarBgColor(params) {
    console.log('改变状态栏颜色传字符串--111', params)
   giwiandafaadig.changeStatusBarBgColor(params)
  },

  // 状态栏字体颜色传Boolean true是深色，false是浅色
  changeStatusIsDarkFont(params) {
    console.log('状态栏字体颜色--')
   giwiandafaadig.changeStatusIsDarkFont(params)
  },
  // 调用客服
  goCustomServicePage() {
    console.log('调用客服-111-')
   giwiandafaadig.costomerservicepage()
  },
  //true是需要重新加载 false直接调自己的刷新
  canReloadHtml(params) {
    console.log('是否重新加载')
   giwiandafaadig.reloadHtmlIsOk(params)
  },
  changePageToHome() {
    console.log('修改密码成功')
   giwiandafaadig.changePageToHome()
  },
  openFace() {
    console.log('活体')
    window.webkit?.messageHandlers.openFace.postMessage('')
  },
  baseInfoGetAuth() {
   console.log('基本信息获取权限')
   giwiandafaadig.calllogsCapture()
  },
  
}
