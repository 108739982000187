import BaseTemplate from './components/baseTemplate'
import SubmitBtn from './components/submitBtn'
import BaseDialog from './components/baseDialog'
import HeaderBase from './components/headerBase'
import EmptyPage from './components/emptyPage'
import PopuAction from './components/PopuAction'

const components = [
  BaseTemplate,
  SubmitBtn,
  BaseDialog,
  HeaderBase,
  EmptyPage,
  PopuAction
]
const install = function(Vue, opts = {}) {
  components.forEach(component => {
    Vue.component(component.name, component)
  })
}

// 导出组件
export default {
  install,
  BaseTemplate,
  SubmitBtn,
  BaseDialog
}
