import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/vant-contrib'; // vant组件引入
import './api/rem/Adaptive'; // 适配rem
import 'vant/lib/index.css'; // 全局引入样式
import '@/styles/index.scss'; // global css
import 'font-roboto/dist/styles/roboto.min.css';
import '@/directive/formatCardNumber';
import '@/directive/inputNumber';
import '@/directive/inputTime';
import H5Object from './api/android'; // 安卓方法
import VueI18n from 'vue-i18n'; // 引入i18
import VueClipboard from 'vue-clipboard2'; // 复制
import { formatCurrency, getCityVal, formatTimes, getAndirodData, getmoney } from '@/utils';
import * as filters from './filters/index';
import i18n from './i18n';
import '@/permission'; // permission control
// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// if (process.env.NODE_ENV === 'environment') {
//   const vConsole = new VConsole()
// }
Vue.prototype.$H5Object = H5Object; // 挂载调用原生方法
Vue.prototype.formatCurrency = formatCurrency;
Vue.prototype.getmoney = getmoney;
Vue.prototype.getCityVal = getCityVal;
Vue.prototype.formatTimes = formatTimes;
Vue.prototype.$andirodData = getAndirodData();
Vue.use(VueI18n); // i18
Vue.use(VueClipboard); // 复制
Vue.config.productionTip = false;
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});
new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app');
