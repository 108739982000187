const Router = [
    {
        path: "/compoundLoan",
        name: "CompoundLoan",
        component: () => import("@/views/compoundLoan/index.vue"),
        meta: { title: "", prevPath: "" },
    },
    {
        path: "/ReloanAmount",
        name: "ReloanAmount",
        component: () => import("@/views/compoundLoan/ReloanAmount.vue"),
        meta: { title: "", prevPath: "" },
    },
];
export default Router;
