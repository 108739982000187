import Vue from 'vue';
// 输入修改次数指令
Vue.directive('inputNumber', {
    inserted(el, options, vnode) {
        console.log(el, 'aaaaaa');
        let number = options.value + 1;
        el.addEventListener('input', function () {
            if (el.value === '') {
                number += 1;
            }
            const option = options.expression;
            if (option.indexOf('.') !== -1) {
                const parameterArray = option.split('.');
                vnode.context[parameterArray[0]][parameterArray[1]] = number;
            }
            else {
                vnode.context[option] = number;
            }
        });
    }
});
