export default {
    title: 'Pagseset-ap',
    changePassWord: 'Magpalit ng password',
    aboutUs: 'Tungkol sa amin',
    privacy: 'Patakaran sa Pribasidad',
    loanAgreement: 'Loan Agreement',
    version: 'Bersyon',
    deleteAccount: 'Isara ang account',
    changeLanguage: 'Magpalit ng wika',
    loginOut: 'Mag Log-out',
    confirmBtn: 'Kumpirmahin',
    cancleBtn: 'Kanselahin',
    authDeleteAccountMsg: 'Walang impormasyong mananatili pagkatapos isara ang account',
    deleteAccountMsg: 'Para magsara ng account, kontakin lamang ang serbisyo sa customer ',
    loginOutMsg: 'Hindi ka makakatanggap ng mga update mula sa platform pagkatapos mag-log out sa app. Kumpirmahin na mag-log out?',
    originalPassword: 'Pakilagay ang lumang password',
    newPassWord: 'Pakilagay ang bagong password ng 6 hanggang 12 digits lamang',
    xinmima: 'Bagong password',
    confirmPassWord: 'Muling ilagay ang bagong password',
    aboutUsRemark: '<p>Kami ay isang online na platform ng pagpapautang kung saan maaari kang mag-aplay para sa mga pautang. Ang proseso ng aplikasyon ng pautang ay simple at ligtas. Ang pagtaas ng halaga ng pautang ay paganahin pagkatapos mabayaran ang nakaraang pautang sa tamang oras. Ang CashAgad ay nagbibigay ng isang matalino, walang hadlang na karanasan na nakakatugon sa iyong kailangan ng pera 24/7.</p> <p>Pangalan ng kumpanya: AIR FISH LENDING CORPORATION</p> <p>Address: Matatag Street, cor V.</p> <p>Luna Diliman Quezon City, Quezon 1100</p> <p>Pilipinas</p>',
    feedback: 'Beri Masukan',
    deleteSuccess: 'Berhasil dihapus',
    oldPassword: 'Lumang password',
};
