export default {
    title: 'Basic information',
    identityInformation: 'Identity information',
    cashWithdraw: 'Account information',
    personInfo: 'Informasi data diri',
    idCrdName: 'Nama',
    idCarNumber: 'Nomor KTP',
    whatsappNumber: 'Personal Whatsapp number(starting from 09)',
    email: 'Email',
    // email: 'Email address',
    educationName: 'Education level',
    educationList: [
        { name: 'Elementary school', val: '1' },
        { name: 'Junior high school', val: '2' },
        { name: 'High school', val: '3' },
        { name: 'University/College', val: '4' },
        { name: 'Graduate education and above', val: '5' },
        { name: 'Other', val: '6' }
    ],
    marryStatus: 'Marital status',
    marryStatusList: [
        { name: 'Single', val: '1' },
        { name: 'Married', val: '2' },
        { name: 'Divorced', val: '3' },
        { name: 'Other', val: '4' }
    ],
    usageOfloan: 'Purpose of the loan',
    usageOfloanList: [
        { name: 'Daily expense', val: '1' },
        { name: 'Reimbursement', val: '2' },
        { name: 'Entertainment', val: '3' },
        { name: 'Rent', val: '4' },
        { name: 'Tourism', val: '5' },
        { name: 'Education', val: '6' },
        { name: 'Medical', val: '7' },
        { name: 'Shopping', val: '8' },
        { name: 'Other', val: '9' }
    ],
    incomeLevel: 'Monthly income',
    incomeLevelList: [
        { name: 'Less than PHP 14000', val: '1' },
        { name: 'PHP 14000-30000', val: '2' },
        { name: 'PHP 30000-60000', val: '3' },
        { name: 'PHP 60000-90000', val: '4' },
        { name: 'More than PHP 90000', val: '5' }
    ],
    liveArea: 'Residential address Province/city/district',
    liveAreaArry: ['Province', 'City', 'District'],
    liveAreaDetail: 'Detailed address',
    addContactInfo: 'Add emergency contact',
    contactInfo: 'Contact',
    contactName: 'Name',
    telephone: 'Mobile phone number',
    telPhoneLogin: 'Please enter the exact phone number starting with 9',
    urgentPeople: 'Emergency contact',
    contactRelationship: 'Relationship to the emergency contact',
    alternateContactList: [
        [
            { name: 'Parent', val: '2' },
            { name: 'Spouse', val: '1' },
            { name: 'Offspring', val: '3' }
        ],
        [
            { name: 'Parent', val: '2' },
            { name: 'Spouse', val: '1' },
            { name: 'Offspring', val: '3' },
            { name: 'Siblings/Sister', val: '4' },
            { name: 'Friend', val: '5' }
        ]
    ],
    addWorkInfo: 'Add work information',
    careerInfo: 'Type of employment',
    careerInfoList: [
        { name: 'Contract worker', val: '1' },
        { name: 'Students', val: '3' },
        { name: 'Self-employed', val: '2' },
        { name: 'Unemployed', val: '4' }
    ],
    companyname: 'Company name',
    companyTel: 'Company phone number',
    companyArea: 'Region of the company',
    companyDetailAddres: 'Address of the company',
    companyIndustry: 'Industry',
    industryList: [
        { name: 'Trading', val: '1' },
        { name: 'Transportation, storage, logistics', val: '2' },
        { name: 'Accommodation, catering, entertainment, tourism', val: '3' },
        { name: 'Real estate, construction, decoration', val: '4' },
        { name: 'Manufacturing', val: '5' },
        { name: 'Services', val: '6' },
        { name: 'Wholesale and Retail', val: '7' },
        { name: 'Medical', val: '8' },
        { name: 'Education and Training', val: '9' },
        { name: 'Agricultural', val: '10' },
        { name: 'Forestry', val: '11' },
        { name: 'Animal husbandry', val: '12' },
        { name: 'Fisheries', val: '13' },
        { name: 'The mining industry', val: '14' },
        { name: 'IT industry', val: '15' },
        { name: 'Public administration and social organization', val: '16' },
        { name: 'Energy (coal, mine, oil, steam)', val: '17' },
        { name: 'Media industry', val: '18' },
        { name: 'Lawyer', val: '19' },
        { name: 'Gaming industry', val: '20' },
        { name: 'Other', val: '99' }
    ],
    workType: 'Jenis Pekerjaan',
    workTypeList: [
        { name: 'Waktu penuh', val: '1' },
        { name: 'Jangka pendek', val: '2' }
    ],
    position: 'jabatan',
    positionList: [
        { name: 'Karyawan biasa', val: '1' },
        { name: 'Manager', val: '2' },
        { name: 'Senior Manager', val: '3' },
        { name: 'Pemegang saham', val: '4' },
        { name: 'Lain - lain', val: '5' }
    ],
    salaryType: 'Salary type',
    payDayTypeList: [
        { name: 'Weekly salary', val: '1' },
        { name: 'Bi-weekly salary', val: '2' },
        { name: 'Monthly salary', val: '3' },
        { name: 'Other', val: '4' }
    ],
    payDay: 'Pay day',
    payDayList: () => { const day = []; for (let i = 1; i <= 31; i++) {
        day.push({ name: i, val: i });
    } return day; },
    weekList: [
        { name: 'Monday', val: '1' },
        { name: 'Tuesday', val: '2' },
        { name: 'Wednesday', val: '3' },
        { name: 'Thursday', val: '4' },
        { name: 'Friday', val: '5' },
        { name: 'Saturday', val: '6' },
        { name: 'Sunday', val: '7' }
    ],
    schoolName: 'Silakan masukkan nama universitas',
    professionalName: 'Silakan masukkan nama jurusan',
    schoolHours: 'waktu masuk universitas',
    graduationTime: 'waktu lulus',
    unemployed: 'lama waktu tidak bekerja',
    unemployedList: [
        { name: 'Di bawah 1 bulan', val: '1' },
        { name: '1-3 bulan', val: '2' },
        { name: '3-6 bulan', val: '3' },
        { name: '6-12 bulan', val: '4' },
        { name: 'Di atas 1 tahun', val: '5' }
    ],
    workOrBusinessYears: ['Years of working', 'years in business'],
    workOrBusinessYearsList: [
        { name: 'Within 1 year', val: '1' },
        { name: '1 to 3 years', val: '2' },
        { name: '3 to 5 years', val: '3' },
        { name: 'More than 5 years', val: '4' }
    ],
    safeTips: 'We will protect your information at all times',
    receiveMsg: 'Submit the application and complete the loan application. Are you sure to leave?',
    withdrawalTips: ['Pease confirm the digital wallet account', 'Please confirm the phone number for cash withdrawal', 'Please confirm the bank information'],
    bankName: 'Bank name',
    bankNumber: 'Bank account',
    paymentMethod: 'Repayment method',
    messageinfotitle: 'Repayment details',
    shouldPay: 'Current Balance',
    paymentDate: 'Repayment date',
    userName: 'User name',
    loadPrice: 'Loan amount',
    loadDate: 'Loan term',
    overdueDay: 'Days of overdue',
    overdueInterest: 'Fines for overdue',
    overdueDamages: 'Overdue liquidated damages',
    nowpay: 'Repay now',
    authentication: 'authentication',
    nextStep: 'Next',
    addwallet: 'Add a digital wallet account',
    digitalWallet: 'Digital wallet',
    remittanceShop: 'Remittance shop',
    bankCard: 'Bank card',
    selectBank: 'Please select the bank',
    inputBankNumber: 'Enter bank account',
    confirmBankNumber: 'Confirm the bank account',
    withdrawalPhone: 'Withdrawal phone Number',
    withdrawalShopTips: 'Please confirm the withdrawal information. The verification code for cash withdrawal will be provided to you after the review.',
    bankNumberIsdiff: 'The bank info does not match',
    bankNumberIsError: 'Please enter the exact bank card number, otherwise the loan will not be able to be deposited',
    bankInfo: 'Bank information',
    withdrawalPhoneError: 'Please enter the exact phone number starting with 9',
    selectReceiving: 'Please choose e-wallet option',
    perfectInfo: 'Please complete the above information',
    selectShop: 'Please select a remittance shop',
    days: (val) => { return (val.values && val.values.day > 1) ? 'days' : 'day'; },
    fillpayeename: 'Fill in the name of the recipient',
    pleasepayeeName: 'Please fill in the correct name, otherwise disbursement cannot be proceed',
    lastname: 'Last name',
    firstname: 'First name',
    middlename: 'Middle name',
    errorname: 'The format of the name you entered is wrong, please enter it in the following format: Last Name, First Name',
    shenhetongguohou: 'Loan will be disbursed directly after review completed. If you wish to cancel the loan, please contact Customer Service within 24 hour from dibursement time.',
    activationEmailTips: 'We have sent a link to your email, click to activate it',
    activation: 'Activation',
    activated: 'Activated',
    successfulActivation: 'Activation successful'
};
