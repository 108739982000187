export default {
    title: 'Akin',
    myCoupons: 'Kupon Saya',
    messagetext: 'Mensahe',
    sysNoticeOpenTips: 'Aktifkan Notifikasi sistem, untuk menghindari pesan baru yang terlewatkan',
    goOpen: 'Buka',
    emailRemark: 'Kotak surat resmi',
    noMsgText: 'Walang mensahe sa kasalukuyan',
    accountText: 'Tagihan',
    noAccountText: 'Wala kang kasalukuyang Record',
    useRule: 'Aturan Penggunaan',
    extensionpay: 'Pahabain ang pagbabayad',
    allPay: 'Magbayad ng buong halaga',
    addLessText: 'Total pinjaman',
    effectiveTicket: 'Kupon yang berlaku',
    uneffectiveTicket: 'Kupon kadaluarsa',
    noCoupons: 'tidak ada data lagi',
    feedBackHolder: 'Minimal 10 huruf',
    PayRecord: 'Talaan ng pagbabayad',
    noReadNum: 'Mayroon kang {num} di-nabasang mga mensahe',
    noMoreData: '——Yun lang——',
    repayment: 'Takdang petsa ng pagbabayad',
    deferredRepayment: 'Na-deferred ang muling pagbabayad',
    formula: 'Naantalang halaga ng pagbabayad = overdue na multa + interes + overdue na parusa',
    payAmount: 'Talagang pagbabayad',
    paymentDate: 'Petsa ng muling pagbabayad',
    paymentMethod: 'Paraan ng pagbabayad',
    loanTimer: 'Bilang ng mga settlements',
    paySuccessTips: 'Pagsalamat sa inyong pagbabayad',
    choosePayType: 'Sinusuportahan ng kodigo ng pagbabayad mo ang sumusunod na mga paraan ng pagbabayad',
    payCode: 'Code para sa muling pagbabayad',
    checkAllpayType: 'Tingnan ang lahat ng opsyon sa pagbabayad',
    messages: 'Mensahe',
    havePaid: 'Ang utang ay binabayaran?',
    payDetailTips: 'Kumuha ng diskuwento para sa ikalawang panahon ng pagbabayad pagkatapos ng unang buong pagbabayad',
    firstPayDay: 'Mga araw ng unang loan period',
    yanchang8tian: 'I-extend and period',
    xuanzehuankuanfangshi: 'ang Gcash ay under maintenance sa ngayon, maaaring magbayad sa kahit saang payment method maliban lang nito.',
    gengxinbanben: 'Mag-update ng bersyon',
    jiancebanmen: 'May nakitang bagong bersyon na V{v}',
    pleasecopelike: 'Pakikopya ang link at magpatuloy sa pag-update sa store',
    copy: '(kopya)',
    pleaseupdataV: 'Mangyaring i-update ang bersyon sa lalong madaling panahon, kung hindi, makakaapekto ito sa iyong paggamit',
    iknow: 'alam ko'
};
