"use strict";
const ZHmodulesFiles = require.context('./modules/ZHmodules', true, /\.ts$/);
const ZHmodules = ZHmodulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = ZHmodulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});
module.exports = {
    ...ZHmodules,
    language: {
        name: '中文'
    },
    public: {
        twoPassIsdiff: '两次密码输入不一致',
        confirmBtn: '确定',
        cancleBtn: '取消',
        submitMessage: '提交',
        pleaseSelect: '请选择',
        detail: '详情',
        copyBtn: '复制',
        copySuccess: '复制成功',
        submitSuccess: '提交成功',
        loading: '加载中...',
        netWorkErr: '网络异常，请检查移动数据或wifi模式',
        unit: '₱',
        unit1: 'PHP'
    }
};
