"use strict";
const PPmodulesFiles = require.context('./modules/PPmodules', true, /\.ts$/);
const PPmodules = PPmodulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = PPmodulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});
module.exports = {
    ...PPmodules,
    language: {
        name: 'Republika ng Pilipinas'
    },
    public: {
        twoPassIsdiff: 'Hindi magkatugma ang mga password',
        confirmBtn: 'Kumpirmahin',
        cancleBtn: 'Kanselahin',
        submitMessage: 'Isumite',
        pleaseSelect: '请选择',
        detail: 'Mga detalye',
        copyBtn: 'Kopyahing',
        copySuccess: 'Kinopya',
        submitSuccess: '提交成功',
        loading: 'Naglo-load...',
        netWorkErr: 'hindi makakonekta sa network',
        unit: '₱',
        unit1: 'PHP'
    }
};
