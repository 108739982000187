export default {
    title: '银行信息',
    bankName: '银行名称',
    bankNumber: '银行账户号码',
    inputBankNumber: '请输入银行账户号码',
    confirmBankNumber: '确认银行账户号码',
    inputConfirmBankNumber: '请再次输入银行账户号码',
    selectBank: '选择银行',
    asBankTips: '两次输入银行账号不一致'
};
