import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
/** *
 * prevPath：h5返回及上一级路径；无值则调用ios的返回
 * isCustomize： 是否自定义返回
 * buryingPointName：埋点函数名称
 */
const routerFiles = require.context('./modules', true, /\.ts$/);
const routerList = routerFiles.keys().map(item => routerFiles(item));
const useRouterList = [];
routerList.forEach(item => useRouterList.push(...item.default));
const routes = useRouterList;
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});
export default router;
