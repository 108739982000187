const state = {
    routename: '',
    andirodData: {},
    isAndirodData: false,
    phoneNumber: '',
    baseInfoSubData: {},
    baseInfoBuryingPointData: {},
    newsDetail: {},
    payDetailData: {},
    buyCouponData: {},
    bankList: [],
    checkBank: '',
    include: ['Login', 'BaseInfo', 'BusinessOwner', 'OfficeWorker', 'Student', 'Unemployed', 'Withdrawal'],
    work_type: 1,
    // 登陆埋点
    point: {
        pwd_duration: 0,
        pwd_updatecount: 0,
        pwd_num: 0,
        sms_duration: 0,
        sms_updatecount: 0,
        sms_num: 0,
        forgot_duration: 0,
        forgot_updatecount: 0,
        forgot_num: 0,
        login_num: 0,
        read_num: 0,
    },
    loanpoint: {
        confirm_num: 0,
        money_duration: 0,
        money_updatecount: 0,
        money_num: 0,
        term_duration: 0,
        term_updatecount: 0,
        term_num: 0,
        apply_duration: 0,
        apply_updatecount: 0,
        apply_num: 0,
    },
};
const mutations = {
    CHANGE_SETTING: (state, { key, value }) => {
        // eslint-disable-next-line no-prototype-builtins
        if (state.hasOwnProperty(key)) {
            console.log(key, value);
            state[key] = value;
        }
    }
};
const actions = {
    changeSetting({ commit }, data) {
        commit('CHANGE_SETTING', data);
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions
};
