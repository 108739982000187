export default {
    fudaijine: '复贷金额',
    jiekuanday: '借款天数<span class="dayclass">{day}天</span>',
    firstloanday: '第一期借款天数',
    day: '天',
    lijishenqing: '立即申请',
    anshihuankuan: '按时还款可重复借款，并且金额递增',
    loanTerm: '借款期限最终以审核结果为准',
    xiangqing: '详情',
    jiekuanjine: '借款金额',
    jiekuantianshu: '借款天数',
    diyiqihuankuan: '第一期借款天数',
    huankuanjihua: '还款计划',
    shouqiqiane: '首期全额换款，可享受第二期优惠',
    fuwufei: '服务费',
    daozhangjie: '到账金额',
    woyiyuedutongyi: '我已阅读并同意  ',
    jiekuanxieyi: '《借款协议》',
    queren: '确认',
    jiekuanriqi: '借款日期 ',
    lookloanxieyi: '请先阅读并同意《借款协议》',
    myLookLoan: '我已阅读并同意借款协议',
};
