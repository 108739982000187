export default {
    title: '设置',
    changePassWord: '修改密码',
    aboutUs: '关于我们',
    privacy: '隐私协议',
    loanAgreement: '借款协议',
    version: '版本号',
    deleteAccount: '注销账号',
    changeLanguage: '切换语言',
    loginOut: '退出登录',
    confirmBtn: '确定',
    cancleBtn: '取消',
    authDeleteAccountMsg: '账号注销后将不保留任何信息',
    deleteAccountMsg: '请联系客服注销账号',
    loginOutMsg: '退出APP将无法收到平台的最新消息，是否确定退出？',
    originalPassword: '请输入原密码',
    newPassWord: '请设置6到12位密码',
    xinmima: '新密码',
    confirmPassWord: '重复密码',
    aboutUsRemark: '<p>我们是一个在线贷款平台，您可以在这里申请贷款。贷款过程简单安全，按时还款可以借到更高额度。同时，CashAgad提供了一种智能、无障碍的体验， 24小时响应您的贷款需求。</p> <p>公司名称：AIR FISH LENDING CORPORATION</p> <p>地址：Matatag Street, cor V. </p> <p>Diliman Quezon City, Quezon 1100</p> <p>Philippines</p>',
    feedback: '意见反馈',
    deleteSuccess: '删除成功',
    oldPassword: '原密码',
};
