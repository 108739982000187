export default {
    wodekaquan: "Mga kupon at alok",
    yishixiao: "Nag-expire na",
    yishiyong: "Ginamit",
    shiyongguize: "Paano gamitin",
    qingxuanzenindeSex: "Mangyaring piliin ang iyong kasarian",
    nan: "Lalaki",
    nv: "Babae",
    qingshurunindeAge: "Mangyaring ilagay ang iyong edad",
    qingshuruzhengquedeAge: "Pakilagay ang tamang edad",
    qingxuanzenindeBirthday: "Mangyaring piliin ang iyong kaarawan",
    nindezhengjianType: "Uri ng dokumento:",
    qingshuruIdcard: "Pakilagay ang iyong ID number",
    qingshiruzhengquedeIdCard: "Pakilagay ang tamang ID number",
    wujilu: "Walang  naitala",
    youxiaoqi: "Panahon ng bisa:",
    weixuanzeyouhuiquan: "Walang napiling mga kupon",
    number12: "12-digit na numero",
    number10: "10-digit na numero",
    nubmer9_12: "9-12 digit na numero",
    stringNumber9: "Mga numero o titik  9-digit na numero",
    stringNumber11: "Mga numero o titik  11-digit na numero",
    stringNumber22_23: "Mga numero o titik na may 22-23 digit",
    number7: "7-digit na numero",
    PRN13stringNumber: "13 digit o titik",
    couponDiscount: "Halaga ng Diskwento ng Kupon",
    checkCoupon: "Suriin ang mga kupon",
    toUse: 'Gamitin',
    withdrawTicket: "Withdrawal Bonus Kupon",
};
