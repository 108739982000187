export default {
    nameTest: 'KTP姓名输入格式有误',
    idCardTest: 'KTP号码输入格式有误',
    rfcTest: '请输入正确的RFC',
    childTest: '请输入正确的孩子数量',
    liveAddressTest: '请输入正确的地址',
    emailTest: 'Mali ang format ng email na iyong nilagay',
    whatsappTest: 'Whatsapp号码输入格式有误',
    companyNameTest: '请输入正确的公司名称',
    schoolNameTest: '请输入正确的学校名称',
    professionalNameTest: '请输入正确的专业名称',
    companyAddressTest: '请输入正确的公司地址',
    iphoneTest: 'Mangyaring magsumite ng 11 digit na numero na nagsisimula sa 09 o 10 digit na numero na',
    iphoneTestNullFirst: 'Numero ng Telepono ng kumpanya',
    asLoclIphoneError: 'Ang emergency contact person ay hindi maaaring pareho sa rehistradong numero ng mobile phone.',
    asIphoneError: 'Hindi maaaring ma duplicate ang emergency contact person',
    bankNumberTset: '请输入银行账号',
    selectRight: 'Paki-piliin mo ang tama na numero ng mobile',
    phoneLengthError: 'Mangyaring maglagay ng isang 10-12 na digitong numero ng mobile phone.'
};
