const Router = [
    {
        path: '/messages',
        name: 'Messages',
        component: () => import('@/views/messages/index.vue'),
        meta: { title: 'userCenter.messagetext', prevPath: 'userCenter' }
    },
    {
        path: '/messagesDetail',
        name: 'MessagesDetail',
        component: () => import('@/views/messages/messagesDetail/index.vue'),
        meta: { title: 'public.detail', prevPath: '/messages' }
    }
];
export default Router;
