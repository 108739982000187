import { areaList } from '@/utils/area-data'
import store from '../store'
import md5 from 'js-md5' // 引入md5加密
/**
 * 时间戳转换成字符串
 * @param {Object} time
 * @param {Object} num
 */

export function formatTimes(time, num) {
  var yy, mm, dd, hh, min, ss
  var formatTime = ''

  if (time) {
    time = String(time)
    if (time.indexOf('-') > -1 || time.indexOf(':') > -1) {
      time = time.replace(/T/g, ' ')
      time = new Date(time.substr(0, 19).replace(/-/g, '/')).getTime()
    }
    time = new Date(parseInt(time))
    yy = time.getFullYear()
    mm = (time.getMonth() + 1)
    if (mm + 1 < 10) {
      mm = '0' + mm
    }
    dd = time.getDate()
    if (dd < 10) {
      dd = '0' + dd
    }
    hh = time.getHours()
    if (hh < 10) {
      hh = '0' + hh
    }
    min = time.getMinutes()
    if (min < 10) {
      min = '0' + min
    }
    ss = time.getSeconds()
    if (ss < 10) {
      ss = '0' + ss
    }
    switch (num) {
      case 0:
        formatTime = yy + mm + dd + hh + min + ss
        break
      case 1:
        formatTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + min + ':' + ss
        break
      case 2:
        formatTime = yy + '/' + mm + '/' + dd + ' ' + hh + ':' + min + ':' + ss
        break
      case 3:
        formatTime = yy + '年' + mm + '月' + dd + '日' + hh + '时' + min + '分' + ss + '秒'
        break
      case 4:
        formatTime = yy + '-' + mm + '-' + dd
        break
      case 5:
        formatTime = yy + '_' + mm + '_' + dd + ' ' + hh + '_' + min + '_' + ss
        break
      case 6:
        formatTime = mm + '/' + dd
        break
      case 7:
        formatTime = hh + ':' + min + ':' + ss
        break
      case 8:
        formatTime = yy + '.' + mm + '.' + dd
        break
      case 9:
        formatTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + min
        break
      case 10:
        formatTime = yy + '年' + mm + '月' + dd + '日'
        break
      case 11:
        formatTime = mm + '月' + dd + '日'
        break
      case 12:
        formatTime = dd + '-' + mm + '-' + yy
        break
    }
  }
  return formatTime
}
/**
 * 将数值转换为简写数值
 * @param num 原始数值
 * @param omitTheDigits 省略位数（默认值4，以万为单位）
 * @param digit 保留小数点位数（默认值1，保留1位小数）
 * @returns {*} 如：1.5万
 */
export function numToChCapital(num, omitTheDigits, digit) {
  var chArr = ['个', '十', '百', '千', '万', '十万', '百万', '千万', '亿']
  var segmentNum = 0
  var num2 = ''

  num = isNaN(parseFloat(num)) ? 0 : parseFloat(num)
  omitTheDigits = isNaN(parseInt(omitTheDigits)) ? 4 : Math.abs(parseInt(omitTheDigits)) < chArr.length ? Math.abs(parseInt(omitTheDigits)) : chArr.length - 1
  digit = isNaN(parseInt(digit)) ? 1 : parseInt(digit) > 0 ? parseInt(digit) : -1

  segmentNum = Math.pow(10, omitTheDigits)

  if (num < segmentNum) {
    return num
  } else {
    num2 = num / segmentNum + ''
    return (num2.indexOf('.') >= 0 ? num2.substr(0, num2.indexOf('.') + 1 + digit) : num2) + chArr[omitTheDigits]
  }
}

/**
 * 将数值四舍五入(保留2位小数)后格式化成金额形式
 *
 * @param num 数值(Number或者String)
 * @return 金额格式的字符串,如'1,234,567.45'
 * @type String
 */
export function formatCurrency(num) {
  num = (num || '').toString().replace(/\$|\,/g, '')
  if (isNaN(num)) { num = '0' }
  var sign = (Number(num) === (num = Math.abs(num)))
  num = Math.floor(num * 100 + 0.50000000001)
  var cents = num % 100
  num = Math.floor(num / 100).toString()
  if (cents < 10) { cents = '0' + cents }
  for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
    num = num.substring(0, num.length - (4 * i + 3)) + ',' +
      num.substring(num.length - (4 * i + 3))
  }
  return (((sign) ? '' : '-') + num + '.' + cents)
}

// 处理提交参数
export function formatParams(data, isArray) {
  // 参数加密
  const allData = []
  let qstr = ''
  const copyobj = {}
  // 加密密钥
  const key = 'secret0ACCdg1lV'
  for (var i in data) {
    const c = i.slice(0, 2) + '12' + i.slice(2, 4) + '_3' + i.slice(4, i.length) + '_45'
    copyobj[c] = data[i]
    if (typeof data[i] === 'string') {
      data[i] = data[i].trim()
    }
    if (Array.isArray(data[i])) {
      for (let k = 0; k < data[i].length; k++) {
        data[i][k] = formatParams(data[i][k], 'isArray')
      }
      copyobj[c] = JSON.stringify(data[i])
    }
    allData.push(i + '=' + data[i])
  }
  data = Object.assign({}, copyobj)
  const canshuData = Array.from(new Set(allData))
  qstr = canshuData.sort().join('&')
  qstr = md5(qstr + key)
  if (!isArray)data.sign = qstr || '' // 记得修改sign的5位字母
  return data
}

// 处理返回参数
export function formatDatas(obj) {
  if (typeof obj === 'string') return
  Object.keys(obj).forEach(item => {
    let str = item.substring(9, item.length - 1)
    if (item.length >= 17) {
      str = item.substring(9, 16) + item.substring(17, item.length)
    }

    let key = item.substring(2, 6) + str

    if (item * 1) key = item
    if (typeof obj[item] === 'object' && !Array.isArray(obj[item])) {
      formatDatas(obj[item])
    }
    if (Array.isArray(obj[item])) {
      obj[item].forEach(arryItem => { formatDatas(arryItem) })
    }
    // console.log(key, item)
    obj[key] = obj[item]
    if (!Array.isArray(obj)) delete obj[item]
  })
  return obj
}

/**
 * 分割 无小数位保留
 * @param {*} value
 * @returns
 */
export function moneyDivision(value) {
  return Number(value).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1.')
}

// 分割 无小数位保留 逗号分隔
export function getmoney(value) { // 分隔符
  return Number(value).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
}

// 获取当前时间戳（秒）
export function getnewtime() {
  return parseInt((new Date().getTime() / 1000).toString())
}

// 当前时间-初始时间
export function getinterval(nowval, oldval) {
  return nowval - oldval
}

// 获取州区
export function getCityVal(cityCode) {
  if (!cityCode) return
  const prv = areaList.province_list
  const city = areaList.city_list
  const pcode = cityCode.substring(0, 2)
  return prv[pcode + '0000'] + '-' + city[cityCode]
}

// 获取ios混合信息
export function getAndirodData() {
  return store.state.settings.andirodData
}

// 页面激活？
export function onVisibilityChange(callback, isDestroyed) {
  var hiddenProperty = 'hidden' in document ? 'hidden'
    : 'webkitHidden' in document ? 'webkitHidden'
      : 'mozHidden' in document ? 'mozHidden'
        : null
  var visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange')
  var onVisibilityChange = function() {
    if (!document[hiddenProperty]) {
      callback && callback(true) // 激活
    } else {
      callback && callback(false)// 退出到后台
    }
  }

  if (isDestroyed) {
    console.log(1)
    document.removeEventListener(visibilityChangeEvent, onVisibilityChange)
  } else {
    console.log(2)
    document.addEventListener(visibilityChangeEvent, onVisibilityChange)
    // document.removeEventListener(visibilityChangeEvent, onVisibilityChange)
  }
}
