export default {
    fudaijine: 'Ang halaga ng paulit na pautang',
    jiekuanday: '<span class="dayclass">{day} araw</span> term ng pautang ',
    firstloanday: 'Mga araw ng unang loan period',
    day: 'days',
    lijishenqing: 'Mag-apply ngayon',
    anshihuankuan: 'Bayaran sa oras upang paganahin ang muling pag-utang na may nadagdagan credit limit',
    loanTerm: 'Ang huling kataga ng pautang ay mapapailalim sa mga resulta ng ebalwasyon',
    xiangqing: 'Mga detalye ',
    jiekuanjine: 'Halaga ng utang',
    jiekuantianshu: 'Term ng utang',
    diyiqihuankuan: 'Mga araw ng unang loan period',
    huankuanjihua: 'Plano sa pagbabayad',
    shouqiqiane: 'Kumuha ng diskuwento para sa ikalawang panahon ng pagbabayad pagkatapos ng unang buong pagbabayad',
    fuwufei: 'Kabayaran ng serbisyo',
    daozhangjie: 'Natangap na halaga',
    woyiyuedutongyi: 'Nabasa at sang-ayon ako sa ',
    jiekuanxieyi: 'Loan Agreement',
    queren: 'Kumpirmahin',
    jiekuanriqi: 'Term ng utang ',
    lookloanxieyi: 'Mangyaring basahin at sumang-ayon sa Kasunduan sa Pautang.',
    myLookLoan: 'Nabasa at sang-ayon ako sa "Loan Agreement"',
};
