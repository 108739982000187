const Router = [
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/register/index.vue'),
        meta: { title: 'login.register', prevPath: 'login' }
    },
    {
        path: '/setPassWord',
        name: 'SetPassWord',
        component: () => import('@/views/register/setPassWord/index.vue'),
        meta: { title: 'login.setPassWord', prevPath: 'register' }
    }
];
export default Router;
