<template>
  <div>
    <van-action-sheet v-model="isVisible" :title="title" @open="openAction" @close="closeAction">
      <div class="content">
        <van-radio-group v-model="emitVal" @change="changeHandle">
          <van-radio v-for="(item,index) in dataList" :key="index" :name="item[valOrName]">
            <template #icon="props">
              <li :class="{'is-active':props.checked}" class="list-item">
                {{ item[valKey] }}
              </li>
            </template>
          </van-radio>
        </van-radio-group>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
export default {
  name: 'PopuAction',
  props: {
    visbile: {
      type: Boolean,
      default: false
    },
    nameString: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: ''
    },
    valOrName: {
      type: String,
      default: 'name'
    },
    valKey: {
      type: String,
      default: 'name'
    }
  },
  data() {
    return {
      isVisible: false,
      emitVal: ''
    }
  },
  watch: {
    value: {
      handler() {
        this.emitVal = this.value + ''
      },
      immediate: true
    },
    visbile: {
      handler() {
        this.isVisible = this.visbile
      },
      immediate: true
    }
  },
  methods: {
    changeHandle() {
      const item = this.dataList.find(item => { return item[this.valOrName] + '' === this.emitVal + '' })

      this.$emit('input', this.emitVal, (item && item.name || ''), this.nameString)
      this.$emit('update:visbile', false)
    },
    closeAction() {
      this.$emit('update:visbile', false)
      this.$emit('close')
    },

    openAction() {
      this.$emit('open')
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.van-action-sheet) {
  // padding: 0 40px;
  min-height: 608px;
  .content{
    padding: 0 40px;
  }
}
:deep(.van-action-sheet__header){
    text-align: center;
    font-size: 40px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #1F1F39;
    padding-bottom: 40px;
    padding-top: 62px;
    line-height: 1;
    .van-icon{
      width: 100px;
      height: 12px;
      background: #F3F4F5;
      border-radius: 6px;
      top: 20px;
      left: 50%;
      margin-left: -50px;
      &::after{
        display: none;
      }
    }
}
.content{
  .list-item{
    height: 100px;
    line-height: 100px;
    border-radius: 24px;
    background: #F7FAFF;
    font-size: 25px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1F1F39;
    margin-bottom: 20px;
    padding-left: 40px;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      width: 42px;
      height: 40px;
      right: 40px;
      top: 50%;
      margin-top: -20px;
      background: url('~@/assets/images/baseInfo/no-checked-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
  .van-radio:last-child .list-item{
    border: none;
  }
  // .list-item.is-active{
  //   color: #0BB8E4FF;
  // }
  .list-item.is-active::after{
    background: url('~@/assets/images/baseInfo/checked-icon.png') no-repeat center center;
    background-size: 100%;
  }
}
:deep(.van-action-sheet__close::before){
  display: none;
}
:deep(.van-action-sheet__close::after){
  content: '';
  position: absolute;
  width: 32px;
  height: 32px;
  right: 32px;
  top: 48px;
  background: url(~@/assets/images/public/close-icon.png) no-repeat center center;
  background-size: 100%;
}
ul,li{
  list-style: none;
}
:deep(.van-radio){
  display: initial;
}
:deep(.van-radio__icon){
  height: auto;
}

:deep(.van-popup--bottom.van-popup--round){
  border-radius: 48px 48px 0px 0px;
}
</style>
