export default {
    title: 'Setting',
    changePassWord: 'Change password',
    aboutUs: 'About us',
    privacy: 'Privacy Policy',
    loanAgreement: 'Loan Agreement',
    version: 'Version',
    deleteAccount: 'Close the account',
    changeLanguage: 'Change language',
    loginOut: 'Log out',
    confirmBtn: 'Confirm',
    cancleBtn: 'Cancel',
    authDeleteAccountMsg: 'No information will be retained after the account is closed',
    deleteAccountMsg: 'To close the account, please contact the customer service ',
    loginOutMsg: 'You will not be able to receive updates from the platform after logging out the app. Confirm to log out?',
    originalPassword: 'Please enter the old password',
    newPassWord: 'Please enter a password of 6 to 12 digits',
    xinmima: 'New password',
    confirmPassWord: 'Re-enter the password',
    aboutUsRemark: '<p>We are an online lending platform where you can apply for loans. The loan application process is simple and safe. Increased loan amount would be enabled after the previous loan is repaid on time. CashAgad provides a smart, barrier-free experience that meets your need for money 24/7.</p> <p>Company name: AIR FISH LENDING CORPORATION</p> <p>Address: Matatag Street, cor V. Luna </p> <p>Diliman Quezon City, Quezon 1100</p> <p>Philippines</p>',
    feedback: 'Beri Masukan',
    deleteSuccess: 'Berhasil dihapus',
    oldPassword: 'Old password',
};
