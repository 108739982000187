export default {
    title: '基本信息',
    identityInformation: '身份信息',
    cashWithdraw: '到账信息',
    personInfo: '个人信息',
    idCrdName: '姓名',
    idCarNumber: '身份证号码',
    whatsappNumber: 'Whatsapp账号',
    email: '邮箱',
    educationName: '学历',
    educationList: [
        { name: '小学', val: '1' },
        { name: '初中', val: '2' },
        { name: '高中', val: '3' },
        { name: '大学', val: '4' },
        { name: '研究生以上', val: '5' },
        { name: '其它', val: '6' }
    ],
    marryStatus: '婚姻状况',
    marryStatusList: [
        { name: '未婚', val: '1' },
        { name: '已婚', val: '2' },
        { name: '离异', val: '3' },
        { name: '其它', val: '4' }
    ],
    usageOfloan: '借款用途',
    usageOfloanList: [
        { name: '日常消费', val: '1' },
        { name: '用于还款', val: '2' },
        { name: '娱乐', val: '3' },
        { name: '租房', val: '4' },
        { name: '旅游', val: '5' },
        { name: '教育', val: '6' },
        { name: '医疗', val: '7' },
        { name: '购物', val: '8' },
        { name: '其他', val: '9' }
    ],
    incomeLevel: '月收入',
    incomeLevelList: [
        { name: '＜14000PHP', val: '1' },
        { name: '14000-30000PHP', val: '2' },
        { name: '30000-60000PHP', val: '3' },
        { name: '60000-90000PHP', val: '4' },
        { name: '大于90000PHP', val: '5' }
    ],
    liveArea: '居住区域',
    liveAreaDetail: '居住详细地址',
    addContactInfo: '添加紧急联系人',
    contactInfo: '联系人信息',
    contactName: '选择联系人',
    telephone: '联系人电话',
    telPhoneLogin: '请输入9开头准确位数电话',
    urgentPeople: '紧急联系人',
    contactRelationship: '联系人关系',
    alternateContactList: [
        [
            { name: '父母', val: '2' },
            { name: '配偶', val: '1' },
            { name: '子女', val: '3' }
        ],
        [
            { name: '父母', val: '2' },
            { name: '配偶', val: '1' },
            { name: '子女', val: '3' },
            { name: '兄弟/姐妹', val: '4' },
            { name: '朋友', val: '5' }
        ]
    ],
    addWorkInfo: '添加工作信息',
    careerInfo: '工作类型',
    careerInfoList: [
        { name: '在职', val: '1' },
        { name: '学生', val: '3' },
        { name: '个体经营者', val: '2' },
        { name: '无业', val: '4' }
    ],
    companyname: '请输入公司名称',
    companyTel: '公司电话',
    companyArea: '公司所在区域',
    companyDetailAddres: '公司详细地址',
    companyIndustry: '行业',
    industryList: [
        { name: '贸易业', val: '1' },
        { name: '交通运输、仓储、物流', val: '2' },
        { name: '住宿、餐饮业、娱乐、旅游', val: '3' },
        { name: '房地产业、建筑、装饰', val: '4' },
        { name: '制造业', val: '5' },
        { name: '服务业', val: '6' },
        { name: '批发和零售', val: '7' },
        { name: '医疗', val: '8' },
        { name: '教育、培训', val: '9' },
        { name: '农业', val: '10' },
        { name: '林业', val: '11' },
        { name: '牧业', val: '12' },
        { name: '渔业', val: '13' },
        { name: '采矿业', val: '14' },
        { name: '互联网', val: '15' },
        { name: '公共管理和社会组织', val: '16' },
        { name: '能源（煤、矿、油、汽）', val: '17' },
        { name: '媒体', val: '18' },
        { name: '律师', val: '19' },
        { name: '博彩业', val: '20' },
        { name: '其他', val: '99' }
    ],
    workType: '工作类型',
    workTypeList: [
        { name: '全职', val: '1' },
        { name: '兼职', val: '2' }
    ],
    position: '请选择职位',
    positionList: [
        { name: '普通员工', val: '1' },
        { name: '中层领导', val: '2' },
        { name: '高层领导', val: '3' },
        { name: '股东/合伙人', val: '4' },
        { name: '其他', val: '5' }
    ],
    salaryType: '发薪方式',
    payDayTypeList: [
        { name: '周薪', val: '1' },
        { name: '双周薪', val: '2' },
        { name: '月薪', val: '3' },
        { name: '其他', val: '4' }
    ],
    payDay: '发薪日',
    payDayList: () => { const day = []; for (let i = 1; i <= 31; i++) {
        day.push({ name: i, val: i });
    } return day; },
    weekList: [
        { name: '星期一', val: '1' },
        { name: '星期二', val: '2' },
        { name: '星期三', val: '3' },
        { name: '星期四', val: '4' },
        { name: '星期五', val: '5' },
        { name: '星期六', val: '6' },
        { name: '星期日', val: '7' }
    ],
    schoolName: '请输入学校名称',
    professionalName: '请输入专业名称',
    schoolHours: '请选择入学时间',
    graduationTime: '请选择毕业时间',
    unemployed: '请选择无业周期',
    unemployedList: [
        { name: '一个月以内', val: '1' },
        { name: '1-3个月', val: '2' },
        { name: '3-6个月', val: '3' },
        { name: '6-12个月', val: '4' },
        { name: '一年以上', val: '5' }
    ],
    workOrBusinessYears: ['工作年限', '经营年限'],
    workOrBusinessYearsList: [
        { name: '1年以内', val: '1' },
        { name: '1年 ~ 3年', val: '2' },
        { name: '3年 ~ 5年', val: '3' },
        { name: '5年以上', val: '4' }
    ],
    safeTips: '我们会始终保护您的信息安全',
    receiveMsg: '提交申请，还差一步即可借款，您是否确认离开',
    withdrawalTips: ['请确认电子钱包账号', '请确认取款手机号', '请确认银行信息'],
    bankName: '银行名称',
    bankNumber: '银行卡号',
    paymentMethod: '还款方式',
    messageinfotitle: '还款明细',
    shouldPay: '应还金额',
    paymentDate: '还款日期',
    userName: '用户姓名',
    loadPrice: '借款金额',
    loadDate: '借款日期',
    overdueDay: '逾期天数',
    overdueInterest: '逾期罚息',
    overdueDamages: '逾期违约金',
    nowpay: '立即还款',
    authentication: '认证',
    nextStep: '下一步',
    addwallet: '添加电子钱包账号',
    digitalWallet: '电子钱包',
    remittanceShop: '汇款店',
    bankCard: '银行卡',
    selectBank: '选择银行',
    inputBankNumber: '输入银行卡号',
    confirmBankNumber: '确认银行卡号',
    withdrawalPhone: '取款手机号',
    withdrawalShopTips: '请确认取款信息，取款需提供验证，审核通过为您提供取款码',
    bankNumberIsdiff: '您输入的银行卡不一致',
    bankNumberIsError: '请输入准确银行卡号，否则钱将无法到账',
    bankInfo: '银行信息',
    withdrawalPhoneError: 'Pakipasok ng eksaktong numero ng telepono na nagsisimula sa 9',
    selectReceiving: '请选择电子钱包',
    perfectInfo: '请完成以上信息填写',
    selectShop: '请选择汇款店',
    days: (val) => { return (val.values && val.values.day > 1) ? '天' : '天'; },
    fillpayeename: '填写收款姓名',
    pleasepayeeName: '请填写您的真实姓名，否则无法收款',
    lastname: 'last name',
    firstname: 'first name',
    middlename: 'middle name',
    errorname: '您输入的姓名格式有误请按照以下格式输入 举例：lastname，firstname',
    shenhetongguohou: '审核通过后将会直接放款，如不需要款项可在放款成功后24小时内联系客服取消订单',
    activationEmailTips: '我们已经向您的电子邮件发送了一个链接，请点击激活。',
    activation: '激活',
    activated: '已激活',
    successfulActivation: '已激活'
};
