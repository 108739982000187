<template>
  <div class="btn-box">
    <div :class="{'can-submit-btn':submitFlag}" class="submit-btn" @click="submit">{{ subText || $t('public.submitMessage') }}</div>
    <p v-if="btnRemark" class="remark">{{ btnRemark }}</p>
  </div>
</template>

<script>
export default {
  name: 'SubmitBtn',
  props: {
    submitFlag: {
      type: Boolean,
      default: false
    },
    subText: {
      type: String,
      default: () => {
        return ''
      }
    },
    btnRemark: {
      type: String,
      default: () => {
        return ''
      }
    },
    needGray: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    submit() {
      if (this.needGray) {
        if (this.submitFlag) {
          this.$emit('submit')
        } else {
          this.$emit('graySubmit')
        }
      } else {
        if (!this.submitFlag) return
        this.$emit('submit')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-box{
  // padding-top: 80px;
  .submit-btn{
    // width: 654px;
    height: 128px;
    line-height: 128px;
    background: #B8B8D2FF;
    border-radius: 32px;
    font-size: 32px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    margin: 0 auto;
    // margin-bottom: 38px;
  }
  .can-submit-btn{
     background: #1C1D21FF;
  }
  .remark{
    padding-bottom: 48px;
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    text-align: center;
  }
}
</style>
