const Router = [
    {
        path: '/userCenter',
        name: 'UserCenter',
        component: () => import('@/views/userCenter/index.vue'),
        meta: { title: 'navbar.mytitle', prevPath: '' }
    },
    {
        path: '/cupones',
        name: 'Cupones',
        component: () => import('@/views/userCenter/cupones/index.vue'),
        meta: { title: 'cupones.wodekaquan', prevPath: '/userCenter' }
    },
    {
        path: '/myCupones',
        name: 'MyCupones',
        component: () => import('@/views/userCenter/cupones/myCupones/index.vue'),
        meta: { title: 'userCenter.myCoupons', prevPath: '' }
    },
    {
        path: '/invalidCupones',
        name: 'InvalidCupones',
        component: () => import('@/views/userCenter/cupones/invalidCupones/index.vue'),
        meta: { title: 'userCenter.uneffectiveTicket', prevPath: '/userCenter' }
    },
    {
        path: '/useRule',
        name: 'UseRule',
        component: () => import('@/views/userCenter/cupones/useRule/index.vue'),
        meta: { title: 'userCenter.useRule', prevPath: '/userCenter' }
    },
    {
        path: '/paymentHistory',
        name: 'PaymentHistory',
        component: () => import('@/views/userCenter/paymentHistory/index.vue'),
        meta: { title: 'userCenter.PayRecord', prevPath: 'userCenter' }
    },
    {
        path: '/aboutUs',
        name: 'AboutUs',
        component: () => import('@/views/userCenter/aboutUs/index.vue'),
        meta: { title: 'settings.aboutUs', prevPath: '/userCenter' }
    },
    {
        path: '/feedBack',
        name: 'FeedBack',
        component: () => import('@/views/userCenter/feedBack/index.vue'),
        meta: { title: 'settings.feedback', prevPath: '' }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/userCenter/settings/index.vue'),
        meta: { title: 'settings.title', prevPath: 'userCenter' }
    },
    {
        path: '/changePassword',
        name: 'ChangePassword',
        component: () => import('@/views/userCenter/settings/changePassword/index.vue'),
        meta: { title: 'settings.changePassWord', prevPath: '/userCenter' }
    }
];
export default Router;
