import Vue from 'vue'
import PublicTemplate from '@/components/publicTemplate'
import {
  Toast,
  DropdownMenu,
  DropdownItem,
  Popup,
  DatetimePicker,
  Collapse,
  CollapseItem,
  Cascader,
  Field,
  Col,
  Row,
  Form,
  Button,
  Cell,
  Dialog,
  CheckboxGroup,
  Checkbox,
  ActionSheet,
  RadioGroup,
  Radio,
  Popover,
  Search,
  IndexBar,
  IndexAnchor,
  Area,
  Sticky,
  NoticeBar,
  List
} from 'vant'
// 公用模版
Vue.use(PublicTemplate)

Vue.use(Toast)// 轻提示
Vue.use(DropdownMenu)// 下拉框
Vue.use(DropdownItem)// 下拉框
Vue.use(Popup)// 弹出层
Vue.use(DatetimePicker)// 时间选择器
Vue.use(Collapse)// 手风琴
Vue.use(CollapseItem)// 手风琴
Vue.use(Cascader)// 级联
Vue.use(Field)
Vue.use(Col)
Vue.use(Row)
Vue.use(Form)
Vue.use(Button)
Vue.use(Cell)
Vue.use(Dialog)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(ActionSheet)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Popover)
Vue.use(Search)
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(Area)
Vue.use(Sticky)
Vue.use(NoticeBar)
Vue.use(List)

