export default {
    title: 'Informasi bank',
    bankName: 'Nama Bank',
    bankNumber: 'Rekening bank',
    inputBankNumber: 'Silakan isi rekening bank',
    confirmBankNumber: 'Konfirmasi rekening bank',
    inputConfirmBankNumber: 'Silakan isi kembali rekening bank',
    selectBank: 'Pilih bank',
    asBankTips: 'Nomor Rekening Bank yang dimasukkan tidak Konsisten'
};
