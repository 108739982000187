const Router = [
    {
        path: '/stayPay',
        name: 'StayPay',
        component: () => import('@/views/stayPay/index.vue'),
        meta: { title: '', prevPath: '' }
    },
    {
        path: '/repayment',
        name: 'Repayment',
        component: () => import('@/views/stayPay/index.vue'),
        meta: { title: 'baseInfo.messageinfotitle', prevPath: '' }
    },
    {
        path: '/paycenterPage',
        name: 'PaycenterPage',
        component: () => import('@/views/stayPay/paycenterPage/paycenterPage.vue'),
        meta: { title: 'baseInfo.messageinfotitle', prevPath: '' }
    },
    // {
    //   path: '/repayment',
    //   name: 'Repayment',
    //   component: () => import('@/views/stayPay/repayment/index.vue'),
    //   meta: { title: 'baseInfo.messageinfotitle', prevPath: '' }
    // },
    {
        path: '/paySuccess',
        name: 'PaySuccess',
        component: () => import('@/views/stayPay/paySuccess/index.vue'),
        meta: { title: '', prevPath: 'repayment' }
    },
    {
        path: '/payDetail',
        name: 'PayDetail',
        component: () => import('@/views/stayPay/payDetail/index.vue'),
        meta: { title: 'baseInfo.messageinfotitle', prevPath: 'repayment' }
    },
    {
        path: '/payRecord',
        name: 'PayRecord',
        component: () => import('@/views/stayPay/payRecord/index.vue'),
        meta: { title: 'navbar.repaymentRecord', prevPath: '' }
    },
    {
        path: '/choosePayType',
        name: 'ChoosePayType',
        component: () => import('@/views/stayPay/choosePayType/index.vue'),
        meta: { title: 'baseInfo.paymentMethod', prevPath: 'repayment' }
    },
    {
        path: '/gcash',
        name: 'Gcash',
        component: () => import('@/views/stayPay/choosePayType/gcash/index.vue'),
        meta: { title: 'Gcash', prevPath: 'choosePayType' }
    },
    {
        path: '/paymaya',
        name: 'Paymaya',
        component: () => import('@/views/stayPay/choosePayType/paymaya/index.vue'),
        meta: { title: 'Paymaya', prevPath: 'choosePayType' }
    },
    {
        path: '/shopeepay',
        name: 'Shopeepay',
        component: () => import('@/views/stayPay/choosePayType/shopeepay/index.vue'),
        meta: { title: 'Shopeepay', prevPath: 'choosePayType' }
    },
    {
        path: '/eleven',
        name: 'Eleven',
        component: () => import('@/views/stayPay/choosePayType/eleven/index.vue'),
        meta: { title: '7-Eleven', prevPath: 'choosePayType' }
    },
    {
        path: '/mlhuillier',
        name: 'Mlhuillier',
        component: () => import('@/views/stayPay/choosePayType/mlhuillier/index.vue'),
        meta: { title: 'Mlhuillier', prevPath: 'choosePayType' }
    },
    {
        path: '/rdpawnshop',
        name: 'Rdpawnshop',
        component: () => import('@/views/stayPay/choosePayType/rdpawnshop/index.vue'),
        meta: { title: 'Rdpawnshop', prevPath: 'choosePayType' }
    },
    {
        path: '/truemoney',
        name: 'Truemoney',
        component: () => import('@/views/stayPay/choosePayType/truemoney/index.vue'),
        meta: { title: 'Truemoney', prevPath: 'choosePayType' }
    },
    {
        path: '/ussc',
        name: 'Ussc',
        component: () => import('@/views/stayPay/choosePayType/ussc/index.vue'),
        meta: { title: 'Ussc', prevPath: 'choosePayType' }
    },
    {
        path: '/unionbank',
        name: 'Unionbank',
        component: () => import('@/views/stayPay/choosePayType/unionbank/index.vue'),
        meta: { title: 'Unionbank', prevPath: 'choosePayType' }
    },
];
export default Router;
