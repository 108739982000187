<template>
  <div id="app">
    <keep-alive :include="$store.state.settings.include">
      <router-view :key="$route.path" class="app-content" />
    </keep-alive>
  </div>
</template>

<script >
export default{
     destroyed() {
       this.$store.dispatch('settings/changeSetting', {
         key: 'andirodData',
         value: ''
       })
     },
    //  watch: {
    //    '$store.state.settings.include':{
    //      handler(val){
    //        console.log(val);
    //        console.log('走App');
    //      }
    //    }
    //  }
}

</script>
