<template>
  <div class="el-empty">
    <div class="el-empty__image" :style="imageStyle">
      <img v-if="image" :src="image" ondragstart="return false">
      <slot v-else name="image">
        <img-empty />
      </slot>
    </div>
    <div class="empty__description">
      <slot v-if="$slots.description" name="description"></slot>
      <p v-else>{{ emptyDescription }}</p>
    </div>
    <div v-if="$slots.default" class="el-empty__bottom">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ImgEmpty from './img-empty.vue'

export default {
  name: 'EmptyPage',
  components: {
    [ImgEmpty.name]: ImgEmpty
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    imageSize: {
      type: Number,
      default: 0
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    emptyDescription() {
      return this.description || ''
    },
    imageStyle() {
      return {
        width: this.imageSize ? `${this.imageSize}px` : ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  padding: 40px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events:none;
}
.empty__description{
  margin-top: 48px;
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
}
</style>
