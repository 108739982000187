<template>
  <div>
    <van-dialog
      v-model="visible"
      :class="{'is-reverse':isReverse,'no-cancle-btn':!showCancelButton}"
      :show-cancel-button="showCancelButton"
      :confirm-button-text="confirmButtonText"
      :cancel-button-text="cancelButtonText"
      :before-close="beforeClose"
      :title="title"
      close-on-click-overlay
      @confirm="confirm"
      @cancel="cancel"
      @closed="closed"
    >
      {{ message }}
      <slot name="node-slot"></slot>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'BaseDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    isControl: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    showCancelButton: {
      type: Boolean,
      default: false
    },
    isReverse: {
      type: Boolean,
      default: false
    },
    confirmButtonText: {
      type: String,
      default: () => {
        return ''
      }
    },
    cancelButtonText: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    init() {
      this.visible = true
    },
    confirm() {
      if (this.isReverse) {
        this.$emit('cancel')
      } else {
        this.$emit('confirm')
      }
    },
    cancel() {
      if (this.isReverse) {
        this.$emit('confirm')
      } else {
        this.$emit('cancel')
      }
    },

    closed() {
      this.$emit('closed')
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        if (this.isControl) {
          done(false)
        } else {
          done()
        }
      } else {
        done()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.van-dialog){
  width: 76%;
  height: auto;
  background: #FFFFFF;
  border-radius: 15px;
  font-family: Roboto-Medium, Roboto;
}
:deep(.van-dialog__cancel){
  height: 100px;
  line-height: 100px;
  font-size: 28px;
  color: #666666;
  flex: 1;
}
:deep(.van-dialog__confirm){
  height: 100px;
  line-height: 100px;
  font-size: 28px;
  color: #3D5CFF;
  flex: 1;
}

:deep([class*=van-hairline]::after){
  border-color:#9d9d9d;
}

// :deep(.van-dialog__footer){
//   // position: absolute;
//   // margin-bottom: 40px;
// }
:deep(.van-dialog__content){
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #696969;
  line-height: 40px;
  padding: 40px;
  text-align: center;
  line-height: 32px;
}

:deep(.no-cancle-btn .van-dialog__confirm){
  // width: 486px;
  // margin-left: 64px;
}

:deep(.van-dialog__header){
  font-size: 30px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #002424;
  padding-top: 50px;
  line-height: 1;
}

</style>
