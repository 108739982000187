const Router = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/login/index.vue'),
        meta: { title: 'login.loginText', prevPath: '' }
    },
    {
        path: '/passLogin',
        name: 'PassLogin',
        component: () => import('@/views/login/passLogin/index.vue'),
        meta: { title: 'login.passWordLogin', prevPath: 'login', isCustomize: true }
    },
    {
        path: '/otpLogin',
        name: 'OtpLogin',
        component: () => import('@/views/login/otpLogin/index.vue'),
        meta: { title: 'login.SMSLogin', prevPath: 'login', isCustomize: true }
    },
    {
        path: '/forgetPass/:forgetPass',
        name: 'ForgetPass',
        component: () => import('@/views/login/forgetPass/index.vue'),
        meta: { title: 'login.forgetPassWord', prevPath: 'passLogin' }
    }
];
export default Router;
