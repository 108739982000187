export default {
    agreePrivacy: ['Sa pamamagitan ng pag-log in, nabasa mo at sumang-ayon ka sa Patakaran ng', 'Privacy'],
    title: '登录/注册',
    inputPhone: 'Pakipasok ng eksaktong numero ng telepono na nagsisimula sa 9',
    //  ng telepono
    phoneNumber: 'Numero ng telepono ng mobile phone',
    nextStep: 'Susunod',
    passWordLogin: 'Password login',
    inputPassWord: 'Ipasok ang password',
    inputPassWordP: 'Pakipasok ng numeric password mula 6 hanggang 12 digit',
    SMSLogin: 'Mag-login sa pamamagitan ng verification code',
    forgetPassword: 'Nakalimutan ang password',
    inputValidCode: 'Ipasok ang verification code',
    getValidCode: 'Kumuha ng verification code',
    reGetValidCode: '<span class="refresh"/>',
    loginText: 'Mag Log-in',
    setPassWord: 'magset ng pasword',
    setPassWordP: 'Mangyaring magtakda ng password ng 6 hanggang 12 digit',
    confirmPassWord: 'Muling ipasok ang password',
    confirmPassWordP: 'Muling ipasok ang password',
    codeSended: 'Naipadala na ang text message',
    sendVoice: 'Kunin ang verification code sa pamamagitan ng voice call',
    invisibility: ['Hindi malinaw? ', ' I-klik para i-refresh ang imahe', 'Hindi malinaw? I-klik para i-refresh ang imahe'],
    tabSmsSend: 'Naipadala na ang text message',
    tabVoiceSend: 'Hiniling ang boses na tawag. Mangyaring sagutin nang mabuti ang tawag'
};
