export default {
    title: 'Pangunahing impormasyon',
    identityInformation: 'Impormasyon ng identity',
    cashWithdraw: 'Impormasyon ng Account',
    personInfo: '个人信息',
    idCrdName: '姓名',
    idCarNumber: '身份证号码',
    whatsappNumber: 'Personal Whatsapp number (simula sa 09)',
    // email: 'Email address',
    email: 'Email',
    educationName: 'Antas ng edukasyon',
    educationList: [
        { name: 'Paaralang elementarya', val: '1' },
        { name: 'Junior high school', val: '2' },
        { name: 'Mataas na Paaralan', val: '3' },
        { name: 'Unibersidad/Kolehiyo', val: '4' },
        { name: 'Nagtapos sa pag-aaral at iba pa', val: '5' },
        { name: 'At iba pa', val: '6' }
    ],
    marryStatus: 'Katayuan ng mag-asawa',
    marryStatusList: [
        { name: 'Solo', val: '1' },
        { name: 'Ikinasal', val: '2' },
        { name: 'Diborsyado', val: '3' },
        { name: 'At iba pa', val: '4' }
    ],
    usageOfloan: 'Layunin ng utang',
    usageOfloanList: [
        { name: 'Araw-araw na gastusin', val: '1' },
        { name: 'Reimbursement', val: '2' },
        { name: 'Libangan', val: '3' },
        { name: 'Upa', val: '4' },
        { name: 'Turista', val: '5' },
        { name: 'Edukasyon', val: '6' },
        { name: 'Medikal', val: '7' },
        { name: 'Shopping', val: '8' },
        { name: 'At iba pa', val: '9' }
    ],
    incomeLevel: 'Buwanang kita',
    incomeLevelList: [
        { name: 'Wala pang PHP 14000', val: '1' },
        { name: 'PHP 14000-30000', val: '2' },
        { name: 'PHP 30000-60000', val: '3' },
        { name: 'PHP 60000-90000', val: '4' },
        { name: 'Higit sa PHP 90000', val: '5' }
    ],
    liveArea: 'Tirahan address Probinsya/lungsod/distrito',
    liveAreaArry: ['Probinsya', 'Lungsod', 'Distrito'],
    liveAreaDetail: 'Detalyadong address',
    addContactInfo: 'Magdagdag ng Emergency Contact',
    contactInfo: 'Makipag-ugnayan',
    contactName: 'Pangalan',
    telephone: 'Numero ng telepono ng mobile phone',
    telPhoneLogin: 'Pakipasok ng eksaktong numero ng telepono na nagsisimula sa 9',
    urgentPeople: 'Emergency contact',
    contactRelationship: 'Relationship to the emergency contact',
    alternateContactList: [
        [
            { name: 'Magulang', val: '2' },
            { name: 'Asawa', val: '1' },
            { name: '(Mga)Anak', val: '3' }
        ],
        [
            { name: 'Magulang', val: '2' },
            { name: 'Asawa', val: '1' },
            { name: '(Mga)Anak', val: '3' },
            { name: 'Kapatid na Babae/Lalake', val: '4' },
            { name: 'Kaibigan', val: '5' }
        ]
    ],
    addWorkInfo: 'Magdagdag ng impormasyon tungkol sa trabaho',
    careerInfo: 'Uri ng trabaho',
    careerInfoList: [
        { name: 'Kontraktual', val: '1' },
        { name: 'Estudyante', val: '3' },
        { name: 'Self-employed', val: '2' },
        { name: 'Walang trabaho', val: '4' }
    ],
    companyname: 'Pangalan ng kumpanya',
    companyTel: 'Numero ng Telepono ng kumpanya',
    companyArea: 'Rehiyon ng kumpanya',
    companyDetailAddres: 'Address ng kumpanya',
    companyIndustry: 'Industry',
    industryList: [
        { name: 'Trading', val: '1' },
        { name: 'Transportation, storage, logistics', val: '2' },
        { name: 'Accommodation, catering, entertainment, tourism', val: '3' },
        { name: 'Real estate, construction, decoration', val: '4' },
        { name: 'Manufacturing', val: '5' },
        { name: 'Services', val: '6' },
        { name: 'Wholesale and Retail', val: '7' },
        { name: 'Medical', val: '8' },
        { name: 'Education and Training', val: '9' },
        { name: 'Agricultural', val: '10' },
        { name: 'Forestry', val: '11' },
        { name: 'Animal husbandry', val: '12' },
        { name: 'Fisheries', val: '13' },
        { name: 'The mining industry', val: '14' },
        { name: 'IT industry', val: '15' },
        { name: 'Public administration and social organization', val: '16' },
        { name: 'Energy (coal, mine, oil, steam)', val: '17' },
        { name: 'Media industry', val: '18' },
        { name: 'Lawyer', val: '19' },
        { name: 'Gaming industry', val: '20' },
        { name: 'Other', val: '99' }
    ],
    workType: '工作类型',
    workTypeList: [
        { name: '全职', val: '1' },
        { name: '兼职', val: '2' }
    ],
    position: '请选择职位',
    positionList: [
        { name: '普通员工', val: '1' },
        { name: '中层领导', val: '2' },
        { name: '高层领导', val: '3' },
        { name: '股东/合伙人', val: '4' },
        { name: '其他', val: '5' }
    ],
    salaryType: 'Uri ng suweldo',
    payDayTypeList: [
        { name: 'Lingguhang suweldo', val: '1' },
        { name: 'Bi-lingguhang suweldo', val: '2' },
        { name: 'Buwanang suweldo', val: '3' },
        { name: 'At iba pa', val: '4' }
    ],
    payDay: 'Araw ng Sahod',
    payDayList: () => { const day = []; for (let i = 1; i <= 31; i++) {
        day.push({ name: i, val: i });
    } return day; },
    weekList: [
        { name: 'Lunes', val: '1' },
        { name: 'Martes', val: '2' },
        { name: 'Miyerkules', val: '3' },
        { name: 'Huwebes', val: '4' },
        { name: 'Biyernes', val: '5' },
        { name: 'Sabado', val: '6' },
        { name: 'Linggo', val: '7' }
    ],
    schoolName: '请输入学校名称',
    professionalName: '请输入专业名称',
    schoolHours: '请选择入学时间',
    graduationTime: '请选择毕业时间',
    unemployed: '请选择无业周期',
    unemployedList: [
        { name: '一个月以内', val: '1' },
        { name: '1-3个月', val: '2' },
        { name: '3-6个月', val: '3' },
        { name: '6-12个月', val: '4' },
        { name: '一年以上', val: '5' }
    ],
    workOrBusinessYears: ['Taon ng pagtatrabaho', 'taon sa negosyo'],
    workOrBusinessYearsList: [
        { name: 'Sa loob ng 1 taon', val: '1' },
        { name: '1 hanggang 3 taon', val: '2' },
        { name: '3 hanggang 5 taon', val: '3' },
        { name: 'Mahigit 5 taon', val: '4' }
    ],
    safeTips: 'Poprotektahan namin ang iyong impormasyon sa lahat ng oras',
    receiveMsg: 'Isumite ang aplikasyon at kumpletuhin ang aplikasyon ng pautang. Sigurado ka bang aalis ka?',
    withdrawalTips: ['Mangyaring kumpirmahin ang digital wallet account', 'Mangyaring kumpirmahin ang numero ng telepono para sa cash withdrawal', 'Mangyaring kumpirmahin ang impormasyon sa bangko'],
    bankName: 'Pangalan ng bangko',
    bankNumber: 'Bank account',
    paymentMethod: 'Paraan ng pagbabayad',
    messageinfotitle: 'Detalye ng muling pagbabayad',
    shouldPay: 'Kasalukuyang Balanse',
    paymentDate: 'Petsa ng muling pagbabayad',
    userName: 'Pangalan ng Gumagamit',
    loadPrice: 'Halaga ng utang',
    loadDate: 'Term ng utang',
    overdueDay: 'Mga araw ng overdue na pagbabayad',
    overdueInterest: 'Multa sa overdue na pagbabayad',
    overdueDamages: 'Likidong pinsala ng overdue na pagbabayad',
    nowpay: 'Muling pagbayad',
    authentication: 'pagpapatunay',
    nextStep: 'Susunod',
    addwallet: 'Magdagdag ng digital wallet account',
    digitalWallet: 'Digital wallet',
    remittanceShop: 'Tindahan ng remittance',
    bankCard: 'Bank card',
    selectBank: 'Piliin lamang ang bangko',
    inputBankNumber: 'Ilagay ang bank account no',
    confirmBankNumber: 'Kumpirmahin ang bank account',
    withdrawalPhone: 'Numero ng telepono ng Withdrawal',
    withdrawalShopTips: 'Mangyaring kumpirmahin ang impormasyon sa withdrawal. Ang verification code para sa cash withdrawal ay ibibigay sa iyo pagkatapos ng pagsusuri.',
    bankNumberIsdiff: 'Hindi tugma ang impormasyon ng banko',
    bankNumberIsError: 'Pakipasok ng eksaktong numero ng bank card, kung hindi ay hindi maideposito ang pautang',
    bankInfo: 'Impormasyon sa bangko',
    withdrawalPhoneError: '请输入09开头11位或9开头10位数字',
    selectReceiving: 'Pumili ng tatanggap na digital wallet',
    perfectInfo: 'Paki-complete ang itaas na impormasyon',
    selectShop: 'Paki-piliin mo ang isang remittance shop',
    days: (val) => { return (val.values && val.values.day > 1) ? 'Days' : 'Days'; },
    fillpayeename: 'Punan ang pangalan ng tatanggap',
    pleasepayeeName: 'Paki punan ang tamang pangalan, kung hindi, hindi maaaring magpatuloy ang disbursement',
    lastname: 'apelyido',
    firstname: 'pangalan',
    middlename: 'Gitnang pangalan',
    errorname: 'Ang format ng pangalan na iyong inilagay ay mali, paki ilagay ito sa sumusunod na format: Apelyido, Pangalan',
    shenhetongguohou: 'Ang halaga ng pautang ay awtomatikong ibibigay pagkatapos makumpleto ang pagsusuri. Kung hindi mo kailangan ang loan, mangyaring makipag-ugnayan sa Customer Service sa loob ng 24 na oras mula sa oras ng disbursement upang ipagpatuloy ang pagkansela.',
    activationEmailTips: 'Aming isinend ang isang link sa iyong email, i-click upang aktivahin ito.',
    activation: 'Aktibasyon',
    activated: 'Aktibo na',
    successfulActivation: 'Na-aktibo na'
};
