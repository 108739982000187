export default {
    title: '个人中心',
    myCoupons: '我的券',
    messagetext: '消息',
    sysNoticeOpenTips: '打开系统通知，避免错过新消息',
    goOpen: '去开启',
    emailRemark: '官方唯一邮箱',
    noMsgText: '暂无消息',
    accountText: '账单',
    noAccountText: '暂无账单',
    useRule: '使用规则',
    extensionpay: '展期还款',
    allPay: '全额还款',
    addLessText: '累计省钱',
    effectiveTicket: '有效的券',
    uneffectiveTicket: '失效的券',
    noCoupons: '暂无数据',
    feedBackHolder: '请输入不少于10个字的描述',
    PayRecord: '还款记录',
    noReadNum: '您有{num}条未读消息',
    noMoreData: '——没有更多了——',
    repayment: '应还款日期',
    deferredRepayment: '延期还款时间',
    formula: '延期还款金额=逾期罚息+利息+逾期违约金',
    payAmount: '还款金额',
    paymentDate: '还款日期',
    paymentMethod: '还款方式',
    loanTimer: '借款期数',
    paySuccessTips: '恭喜您还款成功',
    choosePayType: '您的还款码支持以下还款方式',
    payCode: '还款码',
    checkAllpayType: '查看所有支付方式',
    messages: '消息',
    havePaid: '我已还款',
    payDetailTips: '首期全额还款，可享受第二期优惠',
    firstPayDay: '第一天借款天数',
    yanchang8tian: '延长时间',
    xuanzehuankuanfangshi: 'Gcash 正在维护',
    gengxinbanben: '更新版本',
    jiancebanmen: '检浏到有新的版V{v}',
    pleasecopelike: '请复制链接前往应用市汤更新',
    copy: '(复制)',
    pleaseupdataV: '请尽快更新版本，否则影响您的使用',
    iknow: '我己知道'
};
