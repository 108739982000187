import Vue from 'vue';
Vue.directive('bankCard', {
    inserted: function (el) {
        const input = el.querySelector('.van-field__control');
        input.addEventListener('input', function () {
            // 获取当前光标的位置
            const cursorIndex = input.selectionStart;
            // 字符串中光标之前-的个数
            const lineNumOfCursorLeft = (input.value.slice(0, cursorIndex).match(/ /g) || []).length;
            // 去掉所有-的字符串
            const noLine = input.value.replace(/ /g, '');
            // 去除格式不对的字符并重新插入-的字符串
            const newCardNum = noLine.replace(/\D+/g, '').replace(/(\d{4})/g, '$1 ').replace(/ $/, '');
            // // 改后字符串中原光标之前-的个数
            const newLineNumOfCursorLeft = (newCardNum.slice(0, cursorIndex).match(/ /g) || []).length;
            // 光标在改后字符串中应在的位置
            const newCursorIndex = cursorIndex + newLineNumOfCursorLeft - lineNumOfCursorLeft;
            // 赋新值，nextTick保证-不能手动输入或删除，只能按照规则自动填入
            console.log(newCardNum);
            input.value = newCardNum;
            input.selectionStart = newCursorIndex;
            input.selectionEnd = newCursorIndex;
            // this.$nextTick(() => {
            //   this.account = newCardNum
            //   // 修正光标位置，nextTick保证在渲染新值后定位光标
            //   this.$nextTick(() => {
            //     // selectionStart、selectionEnd分别代表选择一段文本时的开头和结尾位置
            //     input.selectionStart = newCursorIndex
            //     input.selectionEnd = newCursorIndex
            //   })
            // })
        });
    }
});
