export default {
    agreePrivacy: ['登录即表示已阅读并同意', '《隐私政策》'],
    title: '登录/注册',
    inputPhone: '请输入9开头准确位数电话',
    phoneNumber: '手机号',
    nextStep: '下一步',
    passWordLogin: '密码登录',
    inputPassWord: '输入密码',
    inputPassWordP: '请输入数字密码6位到12位',
    SMSLogin: '短信登录',
    forgetPassword: '忘记密码',
    inputValidCode: '输入验证码',
    getValidCode: '获取验证码',
    reGetValidCode: '<span class="refresh"/>',
    loginText: '登录',
    setPassWord: '设置密码',
    setPassWordP: '',
    confirmPassWord: '再次确认密码',
    codeSended: '验证码将发送到您的手机，请注意查收',
    sendVoice: '获取语音验证码',
    invisibility: ['看不清？', '请点击图片刷新', '看不清？请点击图片刷新'],
    tabSmsSend: '短信已发送，请注意查看信息',
    tabVoiceSend: '语音已发送，请注意接听电话'
};
