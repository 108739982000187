export default {
    wodekaquan: "我的卡券",
    yishixiao: "已失效",
    yishiyong: "已使用",
    shiyongguize: "使用规则",
    qingxuanzenindeSex: "请选择您的性别",
    nan: "男",
    nv: "女",
    qingshurunindeAge: "请输入您的年龄",
    qingshuruzhengquedeAge: "请输入正确的年龄",
    qingxuanzenindeBirthday: "请选择您的生日",
    nindezhengjianType: "您的证件类型:",
    qingshuruIdcard: "请输入证件号",
    qingshiruzhengquedeIdCard: "请输入正确的证件号",
    wujilu: "无记录",
    youxiaoqi: "有效期:",
    weixuanzeyouhuiquan: "未选择优惠券",
    number12: "12位数字",
    number10: "10位数字",
    nubmer9_12: "9-12位数字",
    stringNumber9: "字母+数字，共9位",
    stringNumber11: "字母+数字，共11位",
    stringNumber22_23: "数字或字母22-23位",
    number7: "7位数字",
    PRN13stringNumber: "PRN开头+13位数字或字母",
    couponDiscount: "优惠券金额",
    checkCoupon: "查看优惠券",
    toUse: '去使用',
    withdrawTicket: "提现券",
};
