import router from './router';
import store from './store';
import Vue from 'vue';
import i18n from '@/i18n';
import { getAndirodData } from '@/utils';
let andirodData = '';
// 路由守卫
router.beforeEach((to, from, next) => {
    dispatchData('routename', from.name);
    if (process.env.NODE_ENV === 'development' || andirodData) {
        return next();
    }
    i18n.locale = andirodData.Icitundu || 'en';
    window.EAHTMLInitParam = (name) => {
        andirodData = name || '';
        if (andirodData && typeof andirodData === 'string') {
            andirodData = JSON.parse(name);
        }
        console.log('数据====', andirodData);
        dispatchData('andirodData', (andirodData || {}));
        if (andirodData) {
            dispatchData('isAndirodData', true);
            Vue.prototype.$andirodData = getAndirodData();
            andirodData.Icitundu = andirodData.Icitundu;
            const lg = andirodData.Icitundu || 'en';
            i18n.locale = lg;
            const lgObj = JSON.stringify({ key: lg, val: lg });
            localStorage.setItem('isEn', lgObj);
            next();
        }
    };
});
function dispatchData(key, val) {
    store.dispatch('settings/changeSetting', {
        key: key,
        value: val
    });
}
router.afterEach((to, from) => {
    window.scrollTo(0, 0);
});
