<template>
  <div id="fixedBar" :style="bstyle" :class="{ 'is-nobg': noHeadeBg, 'login-mould': isLoginMould }" class="headerbase">
    <!-- iconfont icon-fanhui -->
    <div class="headerpulic-left" @click="goback()" />
    <p :style="style" class="header-text">{{ istitle ? $t($route.meta.title) : title }}</p>
    <div class="headerpulic-lefts"></div>
    <slot name="header-right"></slot>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'HeaderBase',
  props: {
    basestutas: {
      type: Number,
      default: 1
    },
    // 特殊情况加title
    title: {
      type: String,
      default: ''
    },
    // 特殊情况 为false的时候是特殊情况
    istitle: {
      type: Boolean,
      default: true
    },

    // 是否不需要背景
    noHeadeBg: {
      type: Boolean,
      default: false
    },
    // 是否开启渐变
    gradient: {
      type: Boolean,
      default: false
    },
    // 渐变色值
    rgb: {
      type: String,
      default: () => {
        return '66, 142, 253'
      }
    },
    brgb: {
      type: String,
      default: () => {
        return '0, 95, 233'
      }
    },
    useBstyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      style: {},
      bstyle: {},
      opacity: 0,
      offsetTop: 0,

      routeName: this.$route.name
    }
  },
  computed: {
    isLoginMould() {
      const nameArry = ['Login', 'PassLogin', 'Register', 'OtpLogin', 'SetPassWord']
      return nameArry.includes(this.routeName)
    }

  },

  mounted() {
    console.log(this.istitle, this.title);
    window.eaH5PageBack = this.goback
    if (this.gradient) {
      this.offsetTop = document.querySelector('#fixedBar').offsetTop
      window.addEventListener('scroll', this.handleScroll)
    }
  },

  destroyed() {
    if (this.gradient) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },

  methods: {
    goback() {
      const meta = this.$route.meta
      const [isBackH5, isCustomize] = [meta.prevPath, meta.isCustomize]
      const fromRoutename = this.$store.state.settings.routename

      // if (process.env.NODE_ENV === 'development' && !isCustomize) { // 开发环境都走back 省的报错
      //   history.back()
      //   return
      // }

      if ((isBackH5 || isCustomize) && fromRoutename || isCustomize) {
        isCustomize ? this.$emit('headerBack') : history.back()
        return
      }
      if (this.$route.query.from) {
        this.$router.back()
        return
      }
      console.log('ios返回。。。。。。。。')
      if (this.istitle == false) {
        this.$emit('headerBack')
        return
      }
      this.$H5Object.cancleView()
      // this.$H5Object.goBack()
    },

    handleScroll: _.throttle(function () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.opacity = Math.abs(Math.round(scrollTop)) / 60
      this.style = { color: `rgba(${this.rgb},${this.opacity})` }
      if (this.useBstyle) {
        this.bstyle = { background: `rgba(${this.brgb},${this.opacity})` }
      }
    }, 100)

  }

}

</script>

<style scoped lang="scss">
.headerpulic-lefts {
  width: 50px;
}

.headerbase {
  width: 100%;
  height: 88px;
  background: #F4F5FCFF;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 0 30px 0 40px;
  display: flex;
  align-items: center;

  .headerpulic-left {
    // width: 50px;
    // font-size: 36px;
    // color: #999999FF;
    width: 44px;
    height: 44px;
    background: url('~@/assets/images/public/head-back-black-icon.png') no-repeat center center;
    background-size: 100%;
  }

  .header-text {
    width: calc(100% - 100px);
    text-align: center;
    font-size: 32px;
    color: rgba(28, 29, 33, 0);

    span {
      color: #33404F;
      font-weight: bold;
    }
  }

  .headerpulic-right {
    font-size: 28px;
    color: #fff;
    text-decoration: underline;
    font-weight: bold;
    position: absolute;
    right: 40px;
    width: 20%;
    text-align: center;
  }
}

.is-nobg {
  background: none;

  .headerpulic-left {
    color: #ffff;
  }

  .header-text {
    color: #ffff;
  }
}

.login-mould.headerbase {
  background: none;

  .header-text {
    display: none;
  }

  .headerpulic-left {
    width: 66px;
    height: 64px;
    background: url('~@/assets/images/public/head-back-black-icon.png') no-repeat center center;
    background-size: 100%;
  }
}
</style>
