export default {
    fudaijine: 'The amount of repeated loan',
    jiekuanday: '<span class="dayclass">{day} days</span> of loan term',
    firstloanday: 'Tenure of first period',
    day: 'Days',
    lijishenqing: 'Apply now',
    anshihuankuan: 'Repay on time to enable repeated loan with increased credit limit',
    loanTerm: 'The final term of the loan shall be subject to the evaluation results',
    xiangqing: 'Details',
    jiekuanjine: 'Loan amount',
    jiekuantianshu: 'Loan Term',
    diyiqihuankuan: 'Days of the first loan period',
    huankuanjihua: 'Repayment plan',
    shouqiqiane: 'Get a discount for the second repayment period after the first full repayment',
    fuwufei: 'Service fee',
    daozhangjie: 'Received amount',
    woyiyuedutongyi: 'I have read and agree to the ',
    jiekuanxieyi: 'Loan Agreement',
    queren: 'Confirm',
    jiekuanriqi: 'Loan Term ',
    lookloanxieyi: 'Please read and agree to the Loan Agreement.',
    myLookLoan: 'I have read and agreed to the "Loan Agreement"',
};
