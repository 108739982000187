const Router = [
    {
        path: '/baseInfo',
        name: 'BaseInfo',
        component: () => import('@/views/perfectInformation/baseInfo/index.vue'),
        meta: { title: 'baseInfo.title', prevPath: '', isCustomize: true, buryingPointName: 'getBasicInfoPoint' }
    },
    {
        path: '/withdrawal',
        name: 'Withdrawal',
        component: () => import('@/views/perfectInformation/withdrawal/index.vue'),
        meta: { title: 'baseInfo.cashWithdraw', prevPath: '', isCustomize: false, buryingPointName: 'getBasicInfoPoint' }
    },
    {
        path: '/businessOwner',
        name: 'BusinessOwner',
        component: () => import('@/views/perfectInformation/baseInfo/businessOwner/index.vue'),
        meta: { title: 'baseInfo.careerInfoList[0].name', prevPath: '/baseInfo', buryingPointName: 'basicEnterprisePoint' }
    },
    {
        path: '/officeWorker',
        name: 'OfficeWorker',
        component: () => import('@/views/perfectInformation/baseInfo/officeWorker/index.vue'),
        meta: { title: 'baseInfo.careerInfoList[1].name', prevPath: '/baseInfo', buryingPointName: 'basicWorkerPoint' }
    },
    {
        path: '/student',
        name: 'Student',
        component: () => import('@/views/perfectInformation/baseInfo/student/index.vue'),
        meta: { title: 'baseInfo.careerInfoList[2].name', prevPath: '/baseInfo', buryingPointName: 'basicStudentPoint' }
    },
    {
        path: '/unemployed',
        name: 'Unemployed',
        component: () => import('@/views/perfectInformation/baseInfo/unemployed/index.vue'),
        meta: { title: 'baseInfo.careerInfoList[3].name', prevPath: '/baseInfo' }
    },
    {
        path: '/contactsInfo',
        name: 'ContactsInfo',
        component: () => import('@/views/perfectInformation/contactsInfo/index.vue'),
        meta: { title: 'navbar.contactInfo', prevPath: '/userCenter', buryingPointName: 'getBasicInfoPoint' }
    },
    {
        path: '/bankInfo',
        name: 'BankInfo',
        component: () => import('@/views/perfectInformation/bankInfo/index.vue'),
        meta: { title: 'bankInfo.title', prevPath: '', isCustomize: false, buryingPointName: 'getBankPoint' }
    },
    {
        path: '/bankList',
        name: 'BankList',
        component: () => import('@/views/perfectInformation/bankInfo/components/bankList.vue'),
        meta: { title: 'baseInfo.bankInfo', prevPath: 'bankInfo', isCustomize: false, buryingPointName: 'getBankPoint' }
    },
    {
        path: '/openFace',
        name: 'OpenFace',
        component: () => import('@/views/perfectInformation/baseInfo/openFace/index.vue'),
        meta: { title: 'face', prevPath: 'bankInfo', isCustomize: false }
    },
];
export default Router;
