export default {
    wodekaquan: 'Coupons and offers',
    yishixiao: 'Expired',
    yishiyong: 'Used',
    shiyongguize: 'How to use',
    qingxuanzenindeSex: 'Please select your gender',
    nan: 'Male',
    nv: 'Female',
    qingshurunindeAge: 'Please input your age',
    qingshuruzhengquedeAge: 'Please input the correct age',
    qingxuanzenindeBirthday: 'Please select your birthday',
    nindezhengjianType: 'Document type:',
    qingshuruIdcard: 'Please input your ID number',
    qingshiruzhengquedeIdCard: 'Please input the correct ID number',
    wujilu: 'No record',
    youxiaoqi: 'Period of validity:',
    weixuanzeyouhuiquan: "Haven't selected coupons",
    number12: "12-digit number",
    number10: "10-digit number",
    nubmer9_12: "9-12 digit number",
    stringNumber9: "Numbers or letters with，9-digit number",
    stringNumber11: "Numbers or letters with，11-digit number",
    stringNumber22_23: "Numbers or letters with 22-23 digits",
    number7: "7-digit number",
    PRN13stringNumber: "13 digits or letters",
    couponDiscount: "Discount Coupon Amount",
    checkCoupon: "Check coupons",
    toUse: 'Use',
    withdrawTicket: "Withdrawal Bonus Coupon",
};
