import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const lgObj = JSON.parse(localStorage.getItem('isEn') || '{}');
const lang = lgObj.key || 'zh';
const i18n = new VueI18n({
    locale: lang,
    messages: {
        zh: require('./api/lang/zh'),
        en: require('./api/lang/en'),
        ph: require('./api/lang/tl'),
        tl: require('./api/lang/tl') // 菲律宾 语言包
    }
});
export default i18n;
