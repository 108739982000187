<template>
  <div class="main-content" :class="{'no-head':!showHead}">
    <header-base v-if="showHead" :brgb="brgb" :use-bstyle="useBstyle" :rgb="rgb" :gradient="gradient" :no-heade-bg="noHeadeBg" @headerBack="headerBack"
    :title="title"
    :istitle="istitle"
    >
      <template v-slot:header-right>
        <slot name="header-right"></slot>
      </template>
    </header-base>
 
    <div v-if="showHead" class="child-title">
      {{istitle? $t($route.meta.title) : title }}</div>
    <slot name="content-top"></slot>
    <div class="is-content">
      <slot></slot>
      <submit-btn v-if="showBtn" :sub-text="subText" :submit-flag="submitFlag" @submit="submit" />
      <empty-page v-if="isEmpty" ref="emptyPage" :image="emptyImage" :description="description || $t('cupones.wujilu')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTemplate',
  props: {
    title:{
      type: String,
      default: ''
    },
    // 特殊情况 为false的时候是特殊情况
    istitle:{
      type: Boolean,
      default: true
    },
    showHead: {
      type: Boolean,
      default: true
    },

    showBtn: {
      type: Boolean,
      default: false
    },
    subText: {
      type: String,
      default: () => {
        return ''
      }
    },
    submitFlag: {
      type: Boolean,
      default: false
    },
    noHeadeBg: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    isEmpty: {
      type: Boolean,
      default: false
    },
    emptyImage: {
      type: String,
      default: ''
    },
    gradient: {
      type: Boolean,
      default: true
    },
    rgb: {
      type: String,
      default: () => {
        return '28, 29, 33'
      }
    },
    brgb: {
      type: String,
      default: () => {
        return '0, 95, 233'
      }
    },
    useBstyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    headerBack() {
      this.$emit('headerBack')
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
.child-title{
  height: 72px;
  font-size: 48px;
  font-weight: 400;
  color: #1C1D21;
  line-height: 72px;
  padding-top: 8px;
  margin-bottom: 32px;
  padding-left: 40px;
}
</style>
