export default {
    title: 'Mine',
    myCoupons: 'Kupon Saya',
    messagetext: 'Message',
    sysNoticeOpenTips: 'Aktifkan Notifikasi sistem, untuk menghindari pesan baru yang terlewatkan',
    goOpen: 'Buka',
    emailRemark: 'Kotak surat resmi',
    noMsgText: 'There is no message currently',
    accountText: 'Tagihan',
    noAccountText: 'You do not currently have a record ~',
    useRule: 'Aturan Penggunaan',
    extensionpay: 'Extension repayment',
    allPay: 'Repay in full amount',
    addLessText: 'Total pinjaman',
    effectiveTicket: 'Kupon yang berlaku',
    uneffectiveTicket: 'Kupon kadaluarsa',
    noCoupons: 'tidak ada data lagi',
    feedBackHolder: 'Minimal 10 huruf',
    PayRecord: 'Repayment history',
    noReadNum: 'You have {num} unread messages',
    noMoreData: '——That is all——',
    repayment: 'Due date of repayment',
    deferredRepayment: 'due date after extension repayment is',
    formula: 'Delayed repayment amount = overdue fine + interest + overdue penalty',
    payAmount: 'Repayment amount',
    paymentDate: 'Repayment date',
    paymentMethod: 'Repayment method',
    loanTimer: 'Numbers of settlements',
    paySuccessTips: 'Congratulations on your successful repayment',
    choosePayType: 'Your repayment code supports the following repayment methods',
    payCode: 'Repayment code',
    checkAllpayType: 'View all payment options',
    messages: 'Messages',
    havePaid: 'I have already paid?',
    payDetailTips: 'Get a discount for the second repayment period after the first full repayment',
    firstPayDay: 'Days of the first loan period',
    yanchang8tian: 'Loan is extended for ',
    xuanzehuankuanfangshi: 'Gcash is currently under maintenance, please try with another method',
    gengxinbanben: 'Update Version',
    jiancebanmen: 'New version V{v} detected',
    pleasecopelike: 'Please copy the link and proceed to update on store',
    copy: '(copy)',
    pleaseupdataV: 'Please update the version soon, otherwise it will effect your usage',
    iknow: 'I am aware'
};
