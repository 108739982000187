const Router = [
    {
        path: '/agreementAbout',
        name: 'AgreementAbout',
        component: () => import('@/views/agreementAbout/index.vue'),
        meta: { title: '隐私&借款协议', prevPath: '' }
    },
    {
        path: '/privacyAgreement',
        name: 'PrivacyAgreement',
        component: () => import('@/views/agreementAbout/privacyAgreement/index.vue'),
        meta: { title: 'settings.privacy', prevPath: 'register' }
    },
    {
        path: '/loanAgreementUse',
        name: 'LoanAgreementUse',
        component: () => import('@/views/agreementAbout/loanAgreement/loanAgreementUse/index.vue'),
        meta: { title: 'settings.loanAgreement', prevPath: 'loanAgreementUse' }
    },
    {
        path: '/loanAgreementExamine',
        name: 'LoanAgreementExamine',
        component: () => import('@/views/agreementAbout/loanAgreement/loanAgreementExamine/index.vue'),
        meta: { title: 'settings.loanAgreement', prevPath: '' }
    }
];
export default Router;
