export default {
    agreePrivacy: ['By logging in, you have read and agreed to the', 'Privacy Policy'],
    title: 'Masuk / Daftar',
    inputPhone: 'Please enter the exact phone number starting with 9',
    phoneNumber: 'Mobile phone number',
    nextStep: 'Next',
    passWordLogin: 'Password login',
    inputPassWord: 'Enter password',
    inputPassWordP: 'Please enter a numeric password from 6 to 12 digits',
    SMSLogin: 'Login through verification code',
    forgetPassword: 'Forget password',
    inputValidCode: 'Enter the verification code',
    getValidCode: 'Get verification code',
    reGetValidCode: '<span class="refresh"/>',
    loginText: 'Log in',
    setPassWord: 'Set a password',
    setPassWordP: 'Please set a password of 6 to 12 digits',
    confirmPassWord: 'Re-enter the password',
    confirmPassWordP: 'Re-enter the password',
    codeSended: 'Text message has been sent',
    sendVoice: 'Get the verification code via voice call',
    invisibility: ['Not clear?', 'Click to refresh the image', 'Not clear? Click to refresh the image'],
    tabSmsSend: 'Text message has been sent',
    tabVoiceSend: 'The voice call is requested. Please answer the call carefully',
};
