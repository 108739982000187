export default {
    nameTest: 'Format Nama KTP Salah',
    idCardTest: 'Format Nomor KTP Salah',
    rfcTest: '',
    childTest: '',
    liveAddressTest: 'Format Alamat Salah',
    emailTest: 'The email format you entered is incorrect',
    whatsappTest: 'Format Nomor Whatsapp Salah',
    companyNameTest: '',
    schoolNameTest: '',
    professionalNameTest: '',
    companyAddressTest: '',
    // iphoneTest: 'Please enter the exact number starting with 09 or 9',
    iphoneTest: 'Please submit 11 digit number startina with 09 or 10 diqit number',
    iphoneTestNullFirst: 'Company phone number',
    //asLoclIphoneError: 'Emergency contact person cannot be duplicated',
    asLoclIphoneError: 'The emergency contact person cannot be the same as the registered mobile phone number.',
    asIphoneError: 'Emergency contact person cannot be duplicated.',
    bankNumberTset: '',
    selectRight: 'Please select the correct mobile number',
    phoneLengthError: 'Please enter a 10-12 digit mobile phone number.'
};
