"use strict";
const ENmodulesFiles = require.context('./modules/ENmodules', true, /\.ts$/);
const ENmodules = ENmodulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = ENmodulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules;
}, {});
module.exports = {
    ...ENmodules,
    language: {
        name: 'IndonesiaName'
    },
    public: {
        twoPassIsdiff: 'The passwords do not match',
        confirmBtn: 'Confirm',
        cancleBtn: 'Cancel',
        submitMessage: 'Submit',
        pleaseSelect: 'Silakan pilih',
        detail: 'Details',
        copyBtn: 'Copy',
        copySuccess: 'Copied',
        submitSuccess: 'Konfirmasi berhasil',
        loading: 'Loading...',
        netWorkErr: 'Cannot be connected to the network.',
        unit: '₱',
        unit1: 'PHP'
    }
};
