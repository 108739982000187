import Vue from 'vue';
import { getnewtime, getinterval } from '@/utils';
// 输入的时长指令
Vue.directive('inputTime', {
    inserted(el, options, vnode) {
        let start = null;
        el.addEventListener('focus', function () {
            const startA = getnewtime();
            start = startA;
        });
        el.addEventListener('blur', function () {
            let importTime = Number(el.dataset.time);
            importTime += getinterval(getnewtime(), start);
            el.dataset.time = importTime;
            const option = options.expression;
            if (option.indexOf('.') !== -1) {
                const parameterArray = option.split('.');
                vnode.context[parameterArray[0]][parameterArray[1]] = el.dataset.time;
            }
            else {
                vnode.context[option] = el.dataset.time;
            }
        });
    }
});
